import { Plugin, Command } from '@ckeditor/ckeditor5-core'

import { ButtonView, MenuBarMenuListItemButtonView } from '@ckeditor/ckeditor5-ui'

import PdfIcon from '!!raw-loader!./icons/exportpdf.svg'
import WordIcon from '!!raw-loader!./icons/exportword.svg'
import PreviewIcon from '!!raw-loader!./icons/preview.svg'
import PrintIcon from '!!raw-loader!./icons/print.svg'

function isEmpty(value) {
    return value === '' || value === null || value === undefined;
}

class ToggleCommand extends Command {
    refresh() {
        const data = this.editor.getData()

        if (isEmpty(data)) {
            this.isEnabled = false
        }else{
            this.isEnabled = true
        }
    }
}

class FileMenuNewFileCommand extends Command {
    execute() {
        const callback = this.editor.config.get('fileMenu.new')
        if (callback) {
            callback(this.editor)
        }
    }
}

// class FileMenuOpenFileCommand extends Command {
//     constructor(editor) {
//         super(editor)
//         // The find command is always enabled.

//         // Listen for changes in read-only state
//         this.editor.on('change:isReadOnly', (evt, name, isReadOnly) => {
//             this.isEnabled = !isReadOnly

//         })

//         // // Example: Check immediately
//         // if (this.editor.isReadOnly) {
//         //     console.log('Editor started in read-only mode')
//         // }

//         // this.isEnabled = !this.editor.isReadOnly
//         // It does not affect data so should be enabled in read-only mode.
//         // this.affectsData = false
//     }

//     execute() {
//         const callback = this.editor.config.get('fileMenu.open')
//         if (callback) {
//             callback(this.editor)
//         }
//     }
// }

class FileMenuOpenFileCommand extends Command {
    constructor(editor) {
        super(editor)
        // The open command is always enabled.

        this.isEnabled = true
        // It does not affect data so should be enabled in read-only mode.
        this.affectsData = false
    }

    execute() {
        const callback = this.editor.config.get('fileMenu.open')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuSaveCommand extends ToggleCommand {
    execute() {
        const callback = this.editor.config.get('fileMenu.save')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuSaveAsCommand extends ToggleCommand {
    execute( value ) {
        const callback = this.editor.config.get('fileMenu.saveAs')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuPDFCommand extends ToggleCommand {
    execute() {
        const callback = this.editor.config.get('fileMenu.exportPDF')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuWordCommand extends ToggleCommand {
    execute() {
        const callback = this.editor.config.get('fileMenu.exportWord')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuPreviewCommand extends ToggleCommand {
    execute() {
        const callback = this.editor.config.get('fileMenu.preview')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuPrintCommand extends ToggleCommand {
    execute() {
        const callback = this.editor.config.get('fileMenu.print')
        if (callback) {
            callback(this.editor)
        }
    }
}

class FileMenuEditing extends Plugin {
    init() {

        this.editor.commands.add(
            'new', new FileMenuNewFileCommand( this.editor )
        )

        this.editor.commands.add(
            'open', new FileMenuOpenFileCommand( this.editor )
        )

        this.editor.commands.add(
            'save', new FileMenuSaveCommand( this.editor )
        )

        this.editor.commands.add(
            'saveAs', new FileMenuSaveAsCommand( this.editor )
        )

        this.editor.commands.add(
            'pdf', new FileMenuPDFCommand( this.editor )
        )

        this.editor.commands.add(
            'word', new FileMenuWordCommand( this.editor )
        )

        this.editor.commands.add(
            'preview', new FileMenuPreviewCommand( this.editor )
        )

        this.editor.commands.add(
            'print', new FileMenuPrintCommand( this.editor )
        )
    }
}

class FileMenuUI extends Plugin {

    get buttonAttributes() {
        const t = this.editor.t
        return {
            'new': { 'label': t('New File'), 'icon': '', 'keystroke': 'CTRL+N' },
            'open': { 'label': t('Open...'), 'icon': '', 'keystroke': 'CTRL+O' },
            'save': { 'label': t('Save'), 'icon': '', 'keystroke': 'CTRL+S' },
            'saveAs': { 'label': t('Save as...'), 'icon': '', 'keystroke': 'CTRL+SHIFT+S' },
            'pdf': { 'label': t('Export to PDF'), 'icon': PdfIcon, 'keystroke': '' },
            'word': { 'label': t('Export to Word'), 'icon': WordIcon, 'keystroke': '' },
            'preview': { 'label': t('Preview...'), 'icon': PreviewIcon, 'keystroke': '' },
            'print': { 'label': t('Print'), 'icon': PrintIcon, 'keystroke': '' },
        }
    }

    init() { 
        const editor = this.editor
       
        // editor.ui.componentFactory.add( 'save', () => {
        //     const button = new ButtonView()

        //     button.label = 'Save'
        //     button.tooltip = true
        //     button.withText = true

        //     this.listenTo( button, 'execute', () => {
        //         console.log('hey')
        //     } )

        //     return button
        // } )

        editor.ui.componentFactory.add('menuBar:new', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'new')
        })

        editor.ui.componentFactory.add('menuBar:open', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'open')
        })

        editor.ui.componentFactory.add('menuBar:saveAs', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'saveAs')
        })

        editor.ui.componentFactory.add('menuBar:save', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'save')
        })

        editor.ui.componentFactory.add('menuBar:pdf', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'pdf')
        })

        editor.ui.componentFactory.add('menuBar:word', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'word')
        })

        editor.ui.componentFactory.add('menuBar:preview', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'preview')
        })

        editor.ui.componentFactory.add('menuBar:print', () => {
            return this._createButton(MenuBarMenuListItemButtonView, 'print')
        })
    }

    _createButton(ButtonClass, name) {
        const editor = this.editor
        const locale = editor.locale
        const command = editor.commands.get(name)
        const view = new ButtonClass(locale)
        view.set({
            label: this.buttonAttributes[name].label,
            // tooltip: true,
            icon: this.buttonAttributes[name].icon,
            // keystroke: this.buttonAttributes[name].keystroke
        })

        view.bind('isEnabled').to(command, 'isEnabled')
        this.listenTo(view, 'execute', () => {
            editor.execute(name)
        })

        return view
    }
}

export default class FileMenu extends Plugin {
    static get requires() {
        return [ FileMenuEditing, FileMenuUI ]
    }

    // afterInit() {
    //     // this.editor.plugins.get( 'RestrictedEditingModeEditing' ).enableCommand( 'myCommand' );
    // }    
}