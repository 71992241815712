<template>
    <b-card class="card-accent-info" header-tag="header" >
    	<template v-slot:header>
			<div class="d-inline-block mt-1">
    			<i class="fa-regular fa-calendar-check"></i> <strong>Appointment Information</strong>
			</div>
            <template v-if="!patientProcessed">
	            <div class="card-header-actions">
	 				<b-overlay
	                :show="$store.getters['loading/isLoading']('schedule_arrived')"
	                rounded
	                opacity="0.6"
	                spinner-small
	                :spinner-variant="patientArrived ? 'success' : 'primary'"
	                class="d-inline-block">
	                <b-button size="sm" v-if="!patientArrived" variant="primary" @click="onPatientArrived">
	                    <i class="fa-regular fa-circle-play"></i> Arrived
	                </b-button>

	                <b-button size="sm" v-if="patientArrived" variant="outline-success" @click="onPatientArrived"">
	                    <i class="fa-regular fa-circle-check"></i> Undo Arrived
	                </b-button>
	            	</b-overlay>
	            </div>
            </template>

    	</template>
		<validation-observer ref="observer" v-slot="{ passes }">
			<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
				<b-input-group>
					<b-form-select :disabled="isLocked" id="reason_type" v-model="form.reason_type">
						<b-form-select-option disabled :value="null">* Select Reason</b-form-select-option>
						<b-form-select-option v-for="reason in reasonTypes" :key="reason.name" :value="reason.id">
						{{ reason.name }}
						</b-form-select-option>
					</b-form-select>
					<b-form-input :disabled="isLocked" ref="rfe" id="reason" placeholder="* Reason for encounter" v-model="form.reason" />
					<b-form-select :disabled="isLocked" id="insurance" plain v-model="form.insurance">
						<b-form-select-option :value="null">Select Insurance</b-form-select-option>
						<b-form-select-option v-for="insurance in insurances" :disabled="!insurance.is_active" :key="insurance.id" :value="insurance.id">{{ insurance.name }} {{ !insurance.is_active ? '(Inactive)' : '(Active)' }}</b-form-select-option>
					</b-form-select>

				    <template v-if="checkUserPermission('consultation', 'can_change')">
			            <b-input-group-append>
				            <b-button v-if="isLocked" type="button" variant="warning" @click.stop="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
				            <b-button v-if="!isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Update</b-button>
				            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click.stop="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
				            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click.stop="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
			            </b-input-group-append>
				    </template>

				</b-input-group>
		        <validation-provider vid="reason" v-slot="{ errors }">
		            <b-form-invalid-feedback :state="errors[0] ? false : null">
		                {{ errors[0] }}
		            </b-form-invalid-feedback>
		        </validation-provider>
			</b-form>
		</validation-observer>
    </b-card>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
	name: 'consultation-information',
	data () {
		return {
			form: new Form({
				reason_type: null,
				reason: null,
				is_walkin: false,
				insurance: null,
				consistency_token: null,
			}),
			insurances: [],
		}
	},
    computed: {
        ...mapGetters('schedule', ['getPatientProcessed', 'getPatientArrived', 'getCurrentConsultation',]),
        patientProcessed() {
            return this.getPatientProcessed()
        },
        patientArrived() {
            return this.getPatientArrived()
        },
        ...mapGetters('schedule', ['getCurrentConsultation']),
        consultation() {
            return this.getCurrentConsultation()
        },
		...mapGetters('planning', ['getReasonTypes']),
		reasonTypes() {
			return this.getReasonTypes()
		}
	},
	watch: {
		consultation: {
            immediate: true,
            handler(val) {
            	this.form.withData(val)

            	if (val) {
            		this.getInsurances()
            	}

            }
		},
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
	},
	methods: {
		...mapActions("planning", ["getConsultReasonTypes", "updateConsultationInformation"]),
		...mapActions('insurances', ['getInsurancesForCurrentPatient']),
        ...mapActions('schedule', ['setPatientHasArrived']),
        async onPatientArrived() {
            await this.setPatientHasArrived(!this.patientArrived)
        },
		onFormSubmit() {
			this.updateConsultationInformation({ id: this.consultation.id, data: this.form.data() }).then(consultation => {
				this.form.withData(consultation)

				this.notify("Consultation updated", "Consultation was updated successfully")
			})
            .catch(errors => {
                this.$refs.observer?.setErrors(errors?.response?.data)
                this.hasErrors = true
            })
		},
		async getInsurances() {
			this.insurances = await this.getInsurancesForCurrentPatient()
		},
        reset() {
        	this.form.reset()
            this.$nextTick(() => {
            	this.$refs.observer?.reset()
            	this.hasErrors = false
            	this.validated()
            })
        }
 	},
	async mounted() {
		await this.getConsultReasonTypes()
	}
})

</script>