<template>
	<b-tab lazy title-item-class="mb-1" class="p-0">
		<template slot="title">Letters</template>
		<letters-base button-top small fixed />
	</b-tab>
</template>
<script>
import TabExtension from "./TabExtension"
import LettersBase from "@/core/components/letters/LettersBase"

export default TabExtension.extend({
    name: "letters",
    components: {
        'letters-base': LettersBase
    }
})
</script>