<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <validation-provider vid="non_field_errors" v-slot="{ errors }">
            <b-form-group
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <validation-provider vid="company_name" v-slot="{ errors }">
                    <b-form-group
                        :label-size="small ? 'sm' : 'md'"
                        label="Company name"
                        label-for="company_name"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">

                        <vue-bootstrap-autocomplete v-capitalize v-model="employerQuery" :data="employers" placeholder="Search Employer..." :minMatchingChars="1" :serializer="employerSerializer" @hit="onHitEmployer" @input="lookupEmployer">
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                                <div class="d-flex align-items-center">
                                    <span class="ml-1" v-html="htmlText"></span>
                                    <b-button class="ml-auto" size="sm" variant="danger" @click.stop="removeEmployer(data.id)"><i class="fa-regular fa-xmark"></i></b-button>
                                </div>
                            </template>
                            <template slot="noResultsInfo">
                                <span v-if="employerQuery">No employers found</span>
                            </template>
                            <template #append>
                                <b-button :disabled="disableAddEmployer" variant="success" @click="addEmployer"><i class="fa-regular fa-plus"></i></b-button>
                            </template>
                        </vue-bootstrap-autocomplete>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="Days" vid="days" v-slot="{ errors }">
                    <b-form-group
                        :label-size="small ? 'sm' : 'md'"
                        label="Days"
                        label-for="days"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-form-checkbox v-b-tooltip.hover title="Use days" v-model="hasAlternateDate" @change="onAlternateDateChange">
                            </b-form-checkbox>
                            </b-input-group-prepend>
                            <b-form-input :disabled="!hasAlternateDate" type="number" id="days" name="days" min="0" step="0.5" placeholder="0.0" v-model="form.days" readonly />
                            <b-input-group-append>
                                <b-button :disabled="!hasAlternateDate" variant="danger" @click="decreaseDayStep">
                                    <i class="fa-regular fa-minus"></i>
                                </b-button>
                                <b-button :disabled="!hasAlternateDate" variant="success" @click="increaseDayStep">
                                    <i class="fa-regular fa-plus"></i>
                                </b-button>
                            </b-input-group-append>

                        </b-input-group>
                    </b-form-group>
                </validation-provider>

                <hr data-content="OR"/>
                <span class="d-block text-center font-weight-bold">OR</span>
                <hr data-content="OR"/>

                <validation-provider name="Multiple dates" vid="dates" v-slot="{ errors }">
                    <b-form-group
                        :label-size="small ? 'sm' : 'md'"
                        label="Multiple dates"
                        label-for="dates"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">

                        <vc-calendar id="dates" :disabled-dates='hasAlternateDate ? {} : null' :locale="currentDateLocale" is-expanded show-weeknumbers trim-weeks :attributes="attributes" @dayclick="onDayClick" />
                    </b-form-group>
                </validation-provider>
<!--                                     <b-form-row>
                    <b-col>
                        <validation-provider vid="until_date" v-slot="{ errors }">
                            <b-form-group
                                label="From date"
                                label-for="from_date"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-form-datepicker no-close-on-select :disabled="hasAlternateDate" id="from_date" placeholder="From..." v-model="form.from_date" :locale="currentLang" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"></b-form-datepicker>

                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col>
                        <validation-provider vid="until_date" v-slot="{ errors }">
                            <b-form-group
                                label="Until date"
                                label-for="until_date"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-form-datepicker no-close-on-select :disabled="hasAlternateDate" id="until_date" placeholder="Until..." v-model="form.until_date" :locale="currentLang" :min="form.from_date" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"></b-form-datepicker>

                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-form-row> -->
            </b-form-group>
        </validation-provider>
        <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
    </b-form>
    </validation-observer>
</b-card>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
    name: "attests-form",
    data() {
        return {
            form: null,

            hasAlternateDate: true,
            dayStepValue: 0.5,

            days: [],
            employerQuery: "",
            employers: [],
        }
    },
    computed: {
        ...mapState("patients", {
            patient: (state) => state.current
        }),
        getDates() {
            return this.form.dates.map(day => day.date)
        },
        attributes() {
            return this.getDates.map(date => ({
                highlight: true,
                dates: date,
            }))
        },
        disableAddEmployer() {
            // return this.employerQuery && this.employers.length > 0
            return !this.employerQuery || this.employers.some(item => item.name.toLowerCase() === this.employerQuery?.toLowerCase())
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        patient: {
            immediate: true,
            handler(val) {
                const employer = val.demographics?.employer.trim()
                if (employer) {
                    this.employerQuery = employer
                    this.lookupEmployer()
                }

                this.newFormWithDefaults()
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('patients', ['filterEmployers', 'createEmployer', 'deleteEmployer']),

        async addEmployer() {
            const employer = await this.createEmployer({code: this.employerQuery, name: this.employerQuery})
            this.employers.push(employer)
            this.onHitEmployer(employer)
        },
        async removeEmployer(id) {
            await this.deleteEmployer(id)
            this.employers = this.employers.filter(item => item.id !== id )
        },
        employerSerializer(item) {
            return item.name
        },
        onHitEmployer(employer) {
            this.form.populate({company_name: employer.name})
        },
        lookupEmployer: _.debounce(function () {
            this.filterEmployers({ search: this.employerQuery })
                .then((items) => {
                    this.employers = items
            })
        }, 300),
        onAlternateDateChange(value) {
            // clear date form data
            this.form.populate({from_date: null, until_date: null, days: null, dates: []})
        },
        increaseDayStep() {
            const form = this.form.data()
            this.form.populate({days: form.days + this.dayStepValue})
        },
        decreaseDayStep() {
            const form = this.form.data()
            if (form.days > this.dayStepValue) {
                this.form.populate({days: form.days -= this.dayStepValue})
            }
        },
        onFormSubmit() {
            this.callback(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient attest created", "Patient attest was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        newFormWithDefaults() {
            this.form = Form.create({
                company_name: this.employerQuery,
                from_date: null,
                until_date: null,
                days: null,
                dates: [],
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults()

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.hasAlternateDate = true
                this.validated()
            })
        },
        onDayClick(day) {
            const idx = this.form.dates.findIndex(d => d.id === day.id)
            if (idx >= 0) {
                this.form.dates.splice(idx, 1)
            } else {
                this.form.dates.push({
                    id: day.id,
                    date: day.date,
                })
            }
        }
    }
})
</script>