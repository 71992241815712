<template>
    <b-tabs pills card content-class="border-left-0 border-right-0">
        <b-tab active title="Patient Letters" :class="{ 'p-0': hasPadding }">
            <letters-patient :borderless="borderless" :button-top="buttonTop" :small="small" :fixed="fixed" />
        </b-tab>
        <b-tab v-if="checkUserPermission('lettertemplate', 'can_view')" title="Template Builder" :class="{ 'p-0': hasPadding }">
            <letter-template-builder />
        </b-tab>
    </b-tabs>
</template>
<script>
import LetterExtension from "./LetterExtension"

import LettersPatient from "./LettersPatient"
import TemplateBuilder from "./builder/TemplateBuilder"

export default LetterExtension.extend({
    name: 'letters-base',
    props: {
        hasPadding: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'letters-patient': LettersPatient,
        'letter-template-builder': TemplateBuilder
    }
})
</script>