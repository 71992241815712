import session from '@/shared/session'

const state = {
	patient: {
		history: {},
		allergies: [],
		bloodtype: {},
		vitals: [],
		measurements: [],
		attests: [],
	}
}

// getters
const getters = {
	getPatientHistory: (state) => () => {
		return state.patient.history
	},
	getPatientBloodType: (state) => () => {
		return state.patient.bloodtype
	},
	getPatientVitals: (state) => () => {
		return state.patient.vitals
	},
	getPatientMeasurements: (state) => () => {
		return state.patient.measurements
	},
	getPatientAttests: (state) => () => {
		return state.patient.attests
	},
	getPatientAllergies: (state) => () => {
		return state.patient.allergies
	}
}

// actions
const actions = {

	async filterAttestsBatch({ state, commit }, data) {
		const attests = await session
			.get("/api/health/attests/batch/", {
				params: data,
			})
		return attests.data
	},

	async exportBatch({ commit }, data) {
		const batch = await session
			.post(`/api/health/attests/export_batch/`, data)

		return batch.data
	},

	async getCurrentExports({ commit }, data) {
		const batch = await session
			.get(`/api/health/attests/exports_batch/`, {
				params: data
			})

		return batch.data
	},

	async getRunningExports({ commit }, data) {
		const batch = await session
			.get(`/api/health/attests/running_exports_batch/`, {
				params: data
			})

		return batch.data.running
	},

	async getCurrentExport({ commit }, data) {
		const batch = await session
			.get(`/api/health/attests/current_export_batch/`, {
				params: data
			})

		return batch.data
	},

	async getHistoryByPatientId({ commit, state, rootState }) {
		const history  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/history/`)

		commit("set_health", {key: 'history', value: history.data})

		return state.patient.history
	},

	async getAllergiesByPatientId({ commit, state, rootState }) {
		const allergies  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/allergies/`)

		commit("set_health", {key: 'allergies', value: allergies.data})

		return state.patient.vitals
	},

	async getBloodTypeByPatientId({ commit, state, rootState }) {
		const bloodtype  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/bloodtype/`)

		commit("set_health", {key: 'bloodtype', value: bloodtype.data})

		return state.patient.bloodtype
	},

	async getVitalsByPatientId({ commit, state, rootState }) {
		const vitals  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/vitals/`)

		commit("set_health", {key: 'vitals', value: vitals.data})

		return state.patient.vitals
	},

	async getVitalsChartDataByPatientId({ commit, state, rootState }) {
		const chart  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/vitals_chart/`)

		return chart.data
	},

	async getMeasurementsByPatientId({ commit, state, rootState }) {
		const measurements  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/measurements/`)

		commit("set_health", {key: 'measurements', value: measurements.data})

		return state.patient.measurements
	},

	async getMeasurmentsChartDataByPatientId({ commit, state, rootState }) {
		const chart  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/measurements_chart/`)

		return chart.data
	},

	async getAttestsByPatientId({ commit, state, rootState }) {
		const attests  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/attests/`)

		commit("set_health", {key: 'attests', value: attests.data})

		return state.patient.attests
	},

	createPatientHistory({ commit, dispatch, rootState }, data) {
		dispatch("loading/load", "history", { root: true })
		return session
				.post(`/api/health/history/`, {...data, patient: rootState.patients.current?.id})
				.then(() => {
					return dispatch("getHistoryByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "history", { root: true })
	      })
	},

	updatePatientHistory({ commit, dispatch }, data) {
		dispatch("loading/load", "history", { root: true })
		return session
				.put(`/api/health/history/${data.id}/`, data.history)
				.then(() => {
					return dispatch("getHistoryByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "history", { root: true })
	      })
	},

	createPatientBloodType({ commit, dispatch, rootState }, data) {
		dispatch("loading/load", "bloodtype", { root: true })
		return session
				.post(`/api/health/bloodtype/`, {...data, patient: rootState.patients.current?.id})
				.then(() => {
					return dispatch("getBloodTypeByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "bloodtype", { root: true })
	      })
	},

	updatePatientBloodType({ commit, dispatch }, data) {
		dispatch("loading/load", "bloodtype", { root: true })
		return session
				.put(`/api/health/bloodtype/${data.id}/`, data.bloodtype)
				.then(() => {
					return dispatch("getBloodTypeByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "bloodtype", { root: true })
	      })
	},

	createPatientAllergy({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "allergies", { root: true })
		return session
				.post(`/api/health/allergies/`, {...data, patient: rootState.patients.current?.id})
				.then(() => {
					return dispatch("getAllergiesByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "allergies", { root: true })
	      })
	},

	deletePatientAllergy({ dispatch, commit }, id) {
		dispatch("loading/load", "allergies", { root: true })
		return session
				.delete(`/api/health/allergies/${id}/`)
				.then(() => {
					return dispatch("getAllergiesByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "allergies", { root: true })
      })
	},

	async checkForAllergy({ commit, state, rootState }, data) {
			const has_allergy  = await session
				.get(`/api/patients/${rootState.patients.current?.id}/allergy_check/`, {
					params: data
				})

			return has_allergy.data
	},

	createPatientVitalsForCurrentConsult({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "vitals", { root: true })
		return session
				.post(`/api/health/vitals/`, {...data, decursus: rootState.patients.current?.current_consult?.decursus_id})
				.then(() => {
					return dispatch("getVitalsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "vitals", { root: true })
	      })
	},

	createPatientAdminVitals({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "vitals", { root: true })
		return session
				.post(`/api/patients/administration/${rootState.patients.current?.id}/vitals/`, data)
				.then(() => {
					return dispatch("getVitalsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "vitals", { root: true })
	      })
	},

	createPatientVitals({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "vitals", { root: true })
		return session
				.post(`/api/health/vitals/`, {...data, decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getVitalsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "vitals", { root: true })
	      })
	},

	deletePatientVitals({ dispatch, commit }, id) {
		dispatch("loading/load", "vitals", { root: true })
		return session
				.delete(`/api/health/vitals/${id}/`)
				.then(() => {
					return dispatch("getVitalsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "vitals", { root: true })
      })
	},

	createPatientMeasurementsForCurrentConsult({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "measurements", { root: true })
		return session
				.post(`/api/health/measurements/`, {...data, decursus: rootState.patients.current.current_consult?.decursus_id})
				.then(() => {
					return dispatch("getMeasurementsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "measurements", { root: true })
	      })
	},

	createPatientAdminMeasurements({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "measurements", { root: true })
		return session
				.post(`/api/patients/administration/${rootState.patients.current?.id}/measurements/`, data)
				.then(() => {
					return dispatch("getMeasurementsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "measurements", { root: true })
	      })
	},

	createPatientMeasurements({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "measurements", { root: true })
		return session
				.post(`/api/health/measurements/`, {...data, decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getMeasurementsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "measurements", { root: true })
	      })
	},

	deletePatientMeasurements({ dispatch, commit }, id) {
		dispatch("loading/load", "measurements", { root: true })
		return session
				.delete(`/api/health/measurements/${id}/`)
				.then(() => {
					return dispatch("getMeasurementsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "measurements", { root: true })
      })
	},

	createPatientAttestForCurrentConsult({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "attests", { root: true })
		return session
				.post(`/api/health/attests/`, {...data, decursus: rootState.patients.current?.current_consult?.decursus_id})
				.then(() => {
					return dispatch("getAttestsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "attests", { root: true })
	      })
	},

	createPatientAdminAttest({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "attests", { root: true })
		return session
				.post(`/api/patients/administration/${rootState.patients.current?.id}/attests/`, data)
				.then(() => {
					return dispatch("getAttestsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "attests", { root: true })
	      })
	},

	createPatientAttest({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "attests", { root: true })
		return session
				.post(`/api/health/attests/`, {...data, decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getAttestsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "attests", { root: true })
	      })
	},

	deletePatientAttest({ dispatch, commit }, id) {
		dispatch("loading/load", "attests", { root: true })
		return session
				.delete(`/api/health/attests/${id}/`)
				.then(() => {
					return dispatch("getAttestsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "attests", { root: true })
      })
	},

	attesToHTMLById({ commit, state, rootState }, id) {
		return session
				.get(`/api/health/attests/${id}/to_html/`)
				.then(letter => {
					return letter.data
				})
	},
}

// mutations
const mutations = {
	set_health(state, obj) {
		state.patient[obj.key] = obj.value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}