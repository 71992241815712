import session from '@/shared/session'

const state = {
	patient: {
		diagnostics: []
	},

	diagnostic_types: [],
}

// getters
const getters = {
	getPatientDiagnostics: (state) => () => {
		return state.patient.diagnostics
	}
}

// actions
const actions = {
	async filterDiagnosticsTests({ commit, state }, params) {
		const items = await session
			.get('/api/catalogue/referral_tests/', {
					params: params
			})

		return items.data
	},

	async getDiagnosticsByPatientId({ commit, state, rootState }) {
		const diagnostics  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/lab/`)

		commit("set_diagnostics", diagnostics.data)

		return state.patient.diagnostics
	},

	async getLabChartDataByPatientId({ commit, state, rootState }) {
		const chart  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/lab_chart/`)

		return chart.data
	},

	async filterDiagnosticsTemplates({ commit, state }, params) {
		const templates = await session
			.get('/api/lab/templates/', {
					params: params
			})

		return templates.data
	},

	createDiagnosticsTemplate({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "lab-template", { root: true })
		return session
				.post(`/api/lab/templates/`, data)
				.then((template) => {
					return template.data
				})
	      .finally(() => {
	        dispatch("loading/end", "lab-template", { root: true })
	      })
	},

	updateDiagnosticsTemplate({ commit, dispatch }, { id , data }) {
    dispatch("loading/load", "test-template", { root: true })
		return session
			.put(`/api/lab/templates/${id}/`, data)
			.then((template) => {
					return template.data
			})
			.finally(() => {
				dispatch("loading/end", "test-template", { root: true })
			})
	},

	deleteDiagnosticsTemplate({ dispatch }, id) {
		dispatch("loading/load", "lab-template", { root: true })
		return session
				.delete(`/api/lab/templates/${id}/`)
				.then(() => {
					return dispatch("getDiagnosticsTemplates")
				})
      .finally(() => {
        dispatch("loading/end", "lab-template", { root: true })
      })
	},

	createDiagnostics({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "lab", { root: true })
		return session
				.post(`/api/lab/`, {...data, decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getDiagnosticsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "lab", { root: true })
	      })
	},

	createPatientAdminDiagnostics({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "lab", { root: true })
		return session
				.post(`/api/patients/administration/${rootState.patients.current?.id}/diagnostics/`, data)
				.then(() => {
					return dispatch("getDiagnosticsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "lab", { root: true })
	      })
	},

	deleteDiagnostics({ dispatch }, id) {
		dispatch("loading/load", "lab", { root: true })
		return session
				.delete(`/api/lab/${id}/`)
				.then(() => {
					return dispatch("getDiagnosticsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "lab", { root: true })
      })
	},

	uploadLabDocument({ commit, dispatch }, {id, data}) {
    dispatch("loading/load", `document-${id}`, { root: true })
		return session
			.post(`/api/lab/${id}/document/`, data)
			.then((lab) => {
				return dispatch("getDiagnosticsByPatientId")
			})
			.finally(() => {
				dispatch("loading/end", `document-${id}`, { root: true })
			})
	},

	deleteLabDocument({ commit, dispatch }, id) {
    	dispatch("loading/load", `document-${id}`, { root: true })
		return session
			.patch(`/api/lab/${id}/delete_document/`)
			.then((lab) => {
				return dispatch("getDiagnosticsByPatientId")
			})
			.finally(() => {
				dispatch("loading/end", `document-${id}`, { root: true })
			})
	},

	updateDiagnosticTest({ commit, dispatch }, { id, data }) {
    dispatch("loading/load", `lab-test-${id}`, { root: true })
		return session
			.put(`/api/lab/tests/${id}/`, data)
			.then((test) => {
				dispatch("getDiagnosticsByPatientId")
				return test.data
			})
			.finally(() => {
				dispatch("loading/end", `lab-test-${id}`, { root: true })
			})
	},
}

// mutations
const mutations = {
	set_diagnostics(state, diagnostics) {
		state.patient.diagnostics = diagnostics
	},
	set_diagnostic_types(state, types) {
		state.diagnostic_types = types
	},
	set_lab_chart_data(state, chart_data) {
		state.patient.lab_chart_data = chart_data
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}