<template>
<b-tab no-body lazy>
    <template slot="title">
        Logs
    </template>
    <div class="d-flex mb-3">
        <b-button class="ml-auto" :disabled="$store.getters['loading/isLoading']('logs')" variant="primary" @click="reload">
            <i :class="{'fa-spin' : $store.getters['loading/isLoading']('logs')}" class="fa-solid fa-arrows-rotate"></i> Refresh
        </b-button>
    </div>
    <b-table
        ref="table"
        class="m-0"
        responsive
        stacked="sm"
        show-empty
        striped
        hover
        fixed
        small
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :filter-function="filter"
        :busy.sync="$store.getters['loading/isLoading']('logs')"
        :no-sort-reset="true"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        :no-border-collapse="false">

        <template #thead-top="data">
            <b-tr>
                <b-th v-for="(field, index) in fields" :key="index">
                    <template v-if="field.key == 'model_object'">
                        <b-form-select size="sm" v-model="currentType" @change="reload">
                            <b-form-select-option :value="null">All</b-form-select-option>
                            <b-form-select-option v-for="type in logTypes" :key="type" :value="type">{{ type }}
                            </b-form-select-option>
                        </b-form-select>
                    </template>
                </b-th>
            </b-tr>
        </template>

        <template v-slot:cell(model_object)="data">
            {{data.item.model_name}}: {{data.item.object_id}}
        </template>

        <template v-slot:cell(status)="data">
            <template v-if="data.item.log_type == '+'">
                <span class="text-success"><i class="fa-regular fa-plus"></i></span>
            </template>
            <template v-else-if="data.item.log_type == '~'">
                <span class="text-warning"><i class="fa-regular fa-wave-sine"></i></span>
            </template>
            <template v-else-if="data.item.log_type == '-'">
                <span class="text-danger"><i class="fa-regular fa-minus"></i></span>
            </template>
        </template>

        <div slot="table-busy" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
        </div>

        <template v-slot:empty="scope">
            <!-- {{ scope.emptyText }} -->
            <div class="small text-muted text-center my-2">
                <span>n/a</span>
            </div>
        </template>
        
        <template v-slot:cell(actions)="row">
           <b-button v-if="row.item.log_items.length > 0" size="sm" variant="primary" @click="row.toggleDetails"><i class="fa-regular fa-ellipsis"></i></b-button>
        </template>

        <template #row-details="row">
            <b-table
                ref="table-items"
                bordered
                responsive
                stacked="sm"
                fixed
                small
                head-variant="light"
                table-variant="primary"
                :items="row.item.log_items"
                :fields="items_fields">
            </b-table>
        </template>

        <template v-slot:custom-foot="scope">
            <b-tr class="border-bottom">
                <b-td colspan="3" class="align-middle" variant="light">
                    Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }} logs
                </b-td>
                <b-td colspan="2" class="text-right align-middle" variant="light">
                    <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                </b-td>
            </b-tr>
        </template>
    </b-table>
</b-tab>
</template>
<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import TabExtension from "../TabExtension"

import _ from 'lodash'

export default TabExtension.extend({
    name: 'logs',
    data() {
        return {
            fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle', formatter: (value) => `${this.toLocaleDateTime(value)}u` },
                { key: 'model_object', label: 'Type', class: 'small align-middle' },
                { key: 'user.username', label: 'By', class: 'small align-middle text-center' },
                { key: 'status', label: 'Status', class: 'small align-middle text-center' },
                
                { key: "actions", label: "", class: "small align-middle text-right" },
            ],

            items_fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle', formatter: (value) => `${this.toLocaleDateTime(value)}u` },
                { key: 'field_name', label: 'Field', class: 'small align-middle' },
                { key: 'old_value', label: 'Old', class: 'small align-middle' },
                { key: 'new_value', label: 'New', class: 'small align-middle' },
            ],

            currentPage: 1,
            perPage: 10,
            sortedBy: 'id',
            sortedDesc: true,
            isBusy: false,

            logTypes: [],
            currentType: null
        }
    },
    computed: {
        ...mapGetters('patients', ['getPatientLogs']),
        items() {
            return _.cloneDeep(this.getPatientLogs())
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    methods: {
        ...mapActions("patients", ["getLogsForCurrentPatient", "getLogTypesForCurrentPatient"]),
        filter() {
            this.$refs.table.refresh()
        },
        async reload() {
            await this.getLogsForCurrentPatient({model_name: this.currentType })
        }
    },
    async mounted() {
        this.logTypes = await this.getLogTypesForCurrentPatient()
    }
})
</script>