<template>
    <div>
        <div id="top-bar" class="p-1 text-center font-weight-bold border-bottom">{{ form.name ? form.name : 'Untitled' }}</div>
        <ck-editor v-model="editorData" :config="editorConfig" :disabled="!checkUserPermission('lettertemplate', 'can_change')" />
    </div>
</template>
<script>
import Vue from 'vue'

import LetterExtension from '../LetterExtension'
import { mapState, mapGetters, mapActions } from "vuex"

// import Placeholder from '@/core/components/editor/plugins/placeholders/placeholder'
import { Variables, VariablesPatient, VariablesDecursus, VariablesPrescription } from '@/core/components/editor/plugins/variables'

import FileOpenModal from './FileOpenModal'
import FileSaveModal from './FileSaveModal'

export default LetterExtension.extend({
    name: 'letter-template-builder',
    props: {
        hidden: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editorData: '',
            form: new Form({
                name: null,
                category_id: null,
                consistency_token: null
            }),
            editorConfig: {
                menuBar: {
                    removeItems: [ 'menuBar:pdf', 'menuBar:word', 'menuBar:preview', 'menuBar:print' ]
                },

                extraPlugins: [
                    // Placeholder,
                    Variables,
                    VariablesPatient,
                    VariablesDecursus,
                    VariablesPrescription
                ],

                fileMenu: {
                    new: this.newFile,
                    open: this.openFile,
                    saveAs: this.saveAs,
                    save: this.onSave,
                },
                // placeholder: {
                //     variables: this.getVariableItems
                // },

                // autosave: {
                //     // waitingTime: 5000, // in ms
                //     save: this.autosave
                // }
            }
        }
    },
    computed: {
        ...mapState('letters', {
            current: state => state.current.template,
            // tmp_data: state => state.tmp_data
        }),
        // ...mapGetters('letters', ['getTemplates', 'getVariables']),
        ...mapGetters('letters', ['getTemplates']),
        templates() {
            return this.getTemplates()
        },
        // variables() {
        //     return this.getVariables()
        // }
    },
    watch: {
        hidden: {
            handler(val) {
                if (val) {
                    this.setCurrentTemplate(null)
                    this.form.reset()
                    this.editorData = ''
                }
            }
        }
    },
    methods: {
        ...mapActions('letters', ['getTemplatesVariables', 'getLetterTemplates', 'setCurrentTemplate', 'createLetterTemplate', 'updateOrPatchLetterTemplate']),
        reset() {
            this.form.reset()
            this.editorData = ''
            this.setCurrentTemplate(null)
        },
        // getVariableItems() {
        //     return new Promise( resolve => {
        //         setTimeout( () => {
        //             resolve( this.variables )
        //         }, 500 )
        //     })
        // },
        newFile( editor ) {
            this.reset()
        },
        openFile( editor ) {
            const action = this.createPendingAction(editor, 'waiting...')

            var ComponentClass = Vue.extend(FileOpenModal)
            var instance = new ComponentClass({
                parent: this,
                propsData: { items: this.templates, title: 'Templates' }
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                this.stopPendingAction(editor, action)
            })

            instance.$on("selected", (selected) => {
                this.open(selected)
            })

            instance.$mount()
        },
        async open(selected) {
            this.form.populate(selected)
            this.editorData = selected.content
        },
        saveAs( editor ) {
            const action = this.createPendingAction(editor, 'saving as...')

            var ComponentClass = Vue.extend(FileSaveModal)
            var instance = new ComponentClass({
                parent: this,
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                this.stopPendingAction(editor, action)
            })

            instance.$on("save", (data) => {
                this.form.populate(data)
                this.save(editor)
            })

            instance.$mount()
        },
        save( editor ) {
            const action =  this.createPendingAction(editor, 'saving...')
            const data = {...this.form.data(), ...{ content: this.editorData }}

            if (this.current) {

                this.updateOrPatchLetterTemplate({ data: {...data, ...{ consistency_token: this.current.consistency_token }} , method: 'put' })
                .then(template => {
                    this.form.populate(template)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.stopPendingAction(editor, action)
                })
            }else{
                this.createLetterTemplate(data)
                .then(template => {
                    this.form.populate(template)
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.stopPendingAction(editor, action)
                })
            }
        },
        onSave( editor ) {
            if (this.current) {
                this.save( editor )
            }else{
                this.saveAs( editor )
            }
        },
        async autosave( editor ) {
            // this.saveTmpData(this.editorData)
            if (this.current && this.editorData) {
                const template = await this.updateOrPatchLetterTemplate({ content:  this.editorData, method: 'patch' })
                this.form.populate(template)
            }
        },
    },
    async mounted() {
        // await this.getTemplatesVariables()
        await this.getLetterTemplates()
    }
})
</script>