<template>
	<b-modal body-class="p-0" scrollable size="lg" title="Save as" :ok-disabled="noName" v-model="showModal" @hidden="onHidden" @ok="onOk" ok-title="Save" hide-header-close no-close-on-esc no-close-on-backdrop>
		<b-form class="p-3">
			<b-form-group
				class="m-0"
		        label="Save as"
		        label-for="save-as"
		        label-cols-sm="2"
		        label-cols-lg="3"
		        label-align-sm="right"
		      >
	        	<b-form-input id="save-as" v-model="file.name" placeholder="Save as..." @change="reset"></b-form-input>			
			</b-form-group>
            <validation-provider vid="category_id" v-slot="{ errors }">
            <b-form-group
				class="m-0 mt-1"
		        label="Category"
		        label-for="category_id"
		        label-cols-sm="2"
		        label-cols-lg="3"
		        label-align-sm="right"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-select id="category_id" name="category_id" plain v-model="file.category_id">
                    <b-form-select-option :value="null">Select Category</b-form-select-option>
                    <b-form-select-option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>
            </validation-provider>
		</b-form>
	<b-card no-body class="m-0 border-bottom-0 border-right-0 border-left-0 rounded-0">
		<b-tabs v-model="tabIndex" pills card vertical nav-wrapper-class="w-25" content-class="border-right-0 border-bottom-0 border-left-1 border-top-1">
			<template #tabs-end>
				<b-nav-item href="#" @click="activeTab(category.id)" no-body title-item-class="mb-1" class="p-0" v-for="category in categories" :key="category.id">{{ category.name }}</b-nav-item>
			</template>
	        <b-table
	            ref="table"
	            class="m-0"
	            selectable
	            select-mode="single"
	            selected-variant="success"
	            @row-selected="onRowSelected"
	            responsive
	            stacked="sm"
	            show-empty
	            striped
	            borderless
	            hover
	            :items="filteredTemplates"
	            :fields="fields"
	            :no-sort-reset="true"
	            :no-border-collapse="false">

                <template v-slot:cell(actions)="row">
                	<b-button v-if="checkUserPermission('lettertemplate', 'can_delete')" size="sm" variant="danger" @click="onRemoveItem(row.item)"><i class="fa-light fa-xmark"></i></b-button>                     
                </template>

	            <div slot="table-busy" class="text-center text-danger my-2">
	                <b-spinner class="align-middle"></b-spinner>
	                <strong>Loading...</strong>
	            </div>

	            <template v-slot:empty="scope">
	                <div class="small text-muted text-center my-2">
	                    <span>n/a</span>
	                </div>
	            </template>
	        </b-table>
		</b-tabs>
	</b-card>
	</b-modal>
</template>

<script>
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default ModalExtension.extend({
	data() {
		return {
            file: new Form({
                name: '',
                category_id: null,
            }),
			fields: [
				{ key: 'name', label: 'Name', class: 'align-middle'},
				{ key: 'created', label: 'Created', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
				{ key: 'updated', label: 'Updated', class: 'align-middle', formatter: (value) => value ? this.toLocaleDate(value) : 'n/a' },
                { key: "actions", label: "", class: 'align-middle' },
			],
			tabIndex: 0
		}
	},
	computed: {
        ...mapState('letters', {
            current: state => state.current.template,
        }),
        ...mapGetters('letters', ['getTemplates', 'getCategories']),
        categories() {
            return this.getCategories()
        },
        uncategorized() {
        	return this.categories.find(item => item.code === 'uncategorized')
        },
        templates() {
            return this.getTemplates()
        },
        filteredTemplates() {
        	return this.templates.filter(item => item.category ?  item.category.id == this.file.category_id : item)
        },
        noName() {
        	return this.file.name.trim() == ''
        }
	},
	methods: {
        ...mapActions('letters', ['getTemplateCategories', 'setCurrentTemplate', 'deleteLetterTemplate']),

        activeTab(id) {
        	this.file.populate({category_id: id})
        },
		onOk(evt) {
			evt.preventDefault()

			if (!this.file.category_id) {
				this.file.populate({category_id: this.uncategorized.id})
			}

			//update file
			if (this.current) {
				this.confirm(this.current.name)
			}else{
				// new file
				this.$emit('save', this.file.data())
				this.hide()
			}
		},
		confirm(name) {
			this.$bvModal
				.msgBoxConfirm(`"${name}" already exists. Do you want to replace it?`, {
					title: "Unsaved changes",
					size: "sm",
					buttonSize: "lg",
					okVariant: "danger",
					okTitle: "Overwrite",
					cancelTitle: "Cancel",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						this.$emit('save', this.file.data())
						this.hide()
					}
				})
		},
		onRowSelected(row) {
			if (row[0]) {
				this.setCurrentTemplate(row[0])
				this.file.populate({name: row[0].name})
			}
		},
		onRemoveItem(item) {
			this.$bvModal
				.msgBoxConfirm(`Delete ${item.name} ?`, {
					title: "Delete File",
					size: "sm",
					buttonSize: "lg",
					okVariant: "danger",
					okTitle: "Delete",
					cancelTitle: "Cancel",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						this.deleteLetterTemplate(item.id).then(() => {
							this.$refs.table.refresh()
						})
					}
				})
		},
		reset() {
			this.setCurrentTemplate(null)
		}
	},
	async mounted() {
		await this.getTemplateCategories()
	}
})
</script>