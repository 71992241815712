<template>
    <b-card class="card-accent-danger" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-stethoscope"></i> <strong> Vital Functions </strong>
            <div class="card-header-actions">
                <b-link  class="card-header-action" @click="showChart = !showChart">
                    <i class="fa-regular fa-chart-line"></i>
                </b-link>

                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
            <vital-functions-base borderless :callback="createPatientVitals" />
        </b-collapse>

        <vital-functions-chart v-if="showChart" @close="showChart = false" />
    </b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import VitalFunctionsBase from './VitalFunctionsBase'
import Chart from './Chart'
import { mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "vital-functions",
    components: {
        'vital-functions-base': VitalFunctionsBase,
        'vital-functions-chart': Chart
    },
    data() {
        return {
            showChart: false
        }
    },
    methods: {
        ...mapActions('health', ['createPatientVitals']),
    }
})
</script>