<template>
    <div>
        <div v-if="buttonTop" class="mb-3">
            <b-button v-if="checkUserPermission('lab', 'can_add')" @click="updateButton" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
            <b-collapse id="add" v-model="addNew" variant="primary">
                <b-row class="mt-3">
                    <b-col>
                        <ReferralsForm ref="referralsForm" @created="updateButton" :callback="callback" :small="small"/>
                    </b-col>
                    <b-col cols="4">
                        <TemplateSaved ref="referralsTemplatesSaved"/>
                        <TemplateForm ref="referralsTemplateForm"/>
                    </b-col>
                </b-row>
            </b-collapse>
        </div>
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE DIAGNOSTIC REFERRAL ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>

<!--         <b-list-group flush>
            <b-list-group-item class="flex-column align-items-start" v-for="item in items" :key="items.id">
                <div class="d-flex w-100 justify-content-between">
                <b-button size="sm" variant="danger" @click="onRemoveItem(item)"><i class="fa-light fa-xmark"></i></b-button>
                  <h5 class="mb-1">{{ item.type.name }} ({{ item.priority.name }})</h5>
                  <span>{{ toLocaleDate(item.created) }} </span>
                </div>

                <p class="my-2">
                    <ReferralUploadResult :referral="item" />
                </p>
                <Columns :columns="5">
                    <ReferralItemResultForm class="mb-1 border-0" v-for="test in item.diagnostics_tests" :item="test" :key="test.id" />
                </Columns>
              </b-list-group-item>
        </b-list-group> -->

        <b-overlay 
            :show="$store.getters['loading/isLoading']('lab')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    striped
                    hover
                    :fixed="fixed"
                    :small="small"
                    :borderless="borderless"
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('lab')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(type)="data">
                        <span>{{ data.item.type.name }} ({{ data.item.priority.name }})</span>
                    </template>

                    <template v-slot:cell(diagnostics_tests)="data">
                        <b-list-group>
                            <ReferralUploadResult :referral="data.item" />
                            <Columns :columns="3">
                                <ReferralItemResultForm v-for="item in data.value" :item="item" :key="item.id" />
                            </Columns>
                        </b-list-group>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)"><i class="fa-light fa-xmark"></i></b-button>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>

                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="3" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer v-if="!buttonTop">
                <b-button v-if="checkUserPermission('lab', 'can_add')" @click="updateButton" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-row class="mt-3">
                        <b-col>
                            <ReferralsForm ref="referralsForm" @created="updateButton" :callback="callback" :small="small"/>
                        </b-col>
                        <b-col cols="4">
                            <TemplateSaved ref="referralsTemplatesSaved"/>
                            <TemplateForm ref="referralsTemplateForm"/>
                        </b-col>
                    </b-row>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
    </div>
</template>
<script>
import ComponentBaseExtension from "@/core/components/ComponentBaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

import ReferralsForm from './ReferralsForm'
import TemplateSaved from './templates/TemplateSaved'
import TemplateForm from './templates/TemplateForm'

import ReferralUploadResult from './ReferralUploadResult'
import ReferralItemResultForm from './ReferralItemResultForm'
import { Columns } from 'vue-columns'
import _ from 'lodash'

export default ComponentBaseExtension.extend({
    name: "diagnostic-referrals-base",
    components: {
        ReferralsForm,
        TemplateSaved,
        TemplateForm,
        ReferralUploadResult,
        ReferralItemResultForm,
        Columns,
    },
    data() {
        return {
            addNew: false,
            fields: [
                { key: "actions", label: "", class: { "align-baseline" : true, "small": this.small } },
                { key: 'created', label: 'Date/Time', class: { "align-text-top" : true, "small": this.small }, formatter: (value) => this.toLocaleDate(value) },
                { key: 'type', label: 'Type', class: { "align-text-top" : true, "small": this.small } },
                // { key: 'priority.name', label: 'Priority', class: 'font-weight-bold' },
                { key: 'diagnostics_tests', label: 'Tests', class: { "align-middle" : true, "small": this.small } },
                
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            confirm: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('lab', ['getPatientDiagnostics']),
        items() {
            return _.cloneDeep(this.getPatientDiagnostics()).map(item => {
                return {...item, _showDetails: true}
            })
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    methods: {
        ...mapActions('lab', ['getDiagnosticsByPatientId', 'deleteDiagnostics']),
        onRemoveItem(item) {
            this.confirm = true
            this.selected = item
        },
        onConfirmed() {
            this.deleteDiagnostics(this.selected?.id).then(() => {
                this.notify("Patient diagnostics referral removed", "Patient diagnostic referral was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog?.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
        },
        updateButton() {
            this.addNew = !this.addNew
            if(!this.addNew) {
                this.$refs.referralsForm.reset()
                this.$refs.referralsTemplatesSaved.fullReset()
                this.$refs.referralsTemplateForm.reset()
            }
        },
        showItem(item) {
            this.selected = this.selected ? null : item

        },
        openDocument(referral) {
            if(referral.document_extension === ".pdf") {
                window.open(referral.document_url, '_blank')
            }else{
                this.$viewerApi({
                  images: [referral.document_url],
                })
            }
        },
    },
    async mounted() {
        await this.getDiagnosticsByPatientId()
    }
})
</script>