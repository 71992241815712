import session from '@/shared/session'
import downloadjs from 'downloadjs'
import { v5 as uuidv5 } from 'uuid'

// initial state
const state = {
	namespaces: {
		diabetes: '6802dab7-7cc7-4fb7-9262-e6fa31897888',
		procedures: '16405953-b77d-408d-9f43-4c49fb0176c4',
		attests: '48217257-8827-4a28-9f27-6084095ccc61'

// 'a3fb2001-df87-4fcf-b491-77953e210c2d'
// 'bb0e5713-7820-4e5e-9521-b5dcb527e72c'
// 'e00ae867-892d-4c69-9ad8-c82c1734064e'
// 'c47c7076-0973-495a-bd9e-c743aa39ccf7'
// '1cf61003-8dc8-447c-a30b-2fe9aadc0b78'
// '920bb4d0-83f1-48e4-ae97-ac56f0ef7395'
// 'a4c96369-6f8c-49eb-b964-b12bd713f7f3'

	}
}

// getters
const getters = {
	getIdentifier: (state) => (key, name) => {
      return state.namespaces.hasOwnProperty(key) ? uuidv5(name, state.namespaces[key]) : null
    }
}

// actions
const actions = {
	async downloadExport({ commit, state, rootState }, id) {
		const download = await session
			.get(`/api/interchange/exports/${id}/download/`)
		
		const { content, filename, mime_type } = download.data
      	downloadjs(content, filename, mime_type)

      	return true
	},
	async downloadExportBatch({ commit, state, rootState }, id) {
		const download = await session
			.get(`/api/interchange/exports/batch/${id}/download/`)
		
		const { content, filename, mime_type } = download.data
      	downloadjs(content, filename, mime_type)

      	return true
	},

}

// mutations
const mutations = {

}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}