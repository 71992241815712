<template>
    <div>
        <div v-if="buttonTop" class="mb-3">
            <b-button v-if="checkUserPermission('referral', 'can_add')" @click="updateButton" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
            <b-collapse id="add" v-model="addNew" variant="primary">
                <ReferralsForm class="mt-3" ref="referralsForm" @created="updateButton" :callback="callback" :small="small"/>
            </b-collapse>
        </div>
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div v-if="$store.getters['loading/isLoading']('referrals')" class="d-flex align-items-center">
                    <strong>Updating...</strong>
                    <b-spinner class="ml-auto"></b-spinner>
                </div>
                <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-solid fa-cloud-arrow-up font-sm text-success"></i> <strong class="text-success">CREATE REFERRAL ? </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
       <b-overlay 
            :show="$store.getters['loading/isLoading']('referrals')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    striped
                    hover
                    :fixed="fixed"
                    :small="small"
                    :borderless="borderless"
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('referrals')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(actions)="row">
                        <b-button-group size="sm">
                            <b-button v-if="checkUserPermission('referral', 'can_print') && row.item.printable" variant="secondary" @click="printRow(row)"><i class="fa-solid fa-print"></i></b-button>
                            <b-button variant="primary" @click="row.toggleDetails"><i class="fa fa-ellipsis-h"></i></b-button>
                        </b-button-group>

                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                    <template #row-details="row">
                        <h6>Reason for referral: </h6>
                       <span class="small" v-html="row.item.reason"></span>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>
                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="4" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="3" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer v-if="!buttonTop">
                <b-button v-if="checkUserPermission('referral', 'can_add')" @click="updateButton" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <ReferralsForm class="mt-3" ref="referralsForm" @created="updateButton" :callback="callback" :small="small"/>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
    </div>
</template>
<script>
import Vue from 'vue'
import ComponentBaseExtension from '@/core/components/ComponentBaseExtension'
import { mapGetters, mapActions, mapState } from 'vuex'

import ReferralsForm from './ReferralsForm'
import printJS from 'print-js'

export default ComponentBaseExtension.extend({
    name: 'referrals-base',
    components: {
        ReferralsForm
    },
    data() {
        return {
            addNew: false,
            fields: [
                { key: 'created', label: 'Date/Time', class: {"align-middle" : true, "small": this.small }, formatter: (value) => this.toLocaleDate(value) },
                { key: 'physician.name', label: 'Referrer', class: {"align-middle" : true, "small": this.small } },
                { key: 'type.name', label: 'Type', class: {"align-middle" : true, "small": this.small } },
                { key: 'priority.name', label: 'Priority', class: {"align-middle" : true, "small": this.small } },
                { key: 'receiving', label: 'Receiving', class: {"align-middle" : true, "small": this.small } },
                { key: 'consulting_doctor', label: 'Consulting', class: {"align-middle" : true, "small": this.small }, formatter: (value) => value ? value : 'n/a' },
                { key: "actions", label: "", class: {"align-middle" : true,  "text-right": true, "small": this.small } },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            confirm: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('referrals', ['getPatientReferrals']),
        items() {
            return _.cloneDeep(this.getPatientReferrals())
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    methods: {
        ...mapActions('referrals', ['getReferralsByPatientId', 'referralPrintableById']),
        onRemoveItem(row) {
            this.confirm = true
            this.selected = row.item
        },
        onConfirmed() {
            // if (this.selected) {
                
            //     this.deletePatientPrescription(this.selected.id).then(() => {
            //         this.notify("Patient prescription removed", "Patient prescription was removed successfully")
            //         this.confirm = false
            //         this.selected = null

            //     })
            //     .catch(errors => {
            //         this.notify("Error", "Something went wrong", 'danger')
            //         this.confirm = false
            //         this.selected = null
            //     })
            // }
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog?.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
        },
        async printRow(row) {
            const referral = await this.referralPrintableById(row.item.id)
            printJS({
                printable: referral,
                type: 'pdf',
                base64: true
            })
        },
        updateButton() {
            this.addNew = !this.addNew
            if(!this.addNew) {
                this.$refs.referralsForm.reset()
            }
        },
    },
    async mounted() {
        await this.getReferralsByPatientId()
    }

})
</script>