<template>
	<b-card class="mb-0" header-tag="header" footer-tag="footer" no-body>
	    <template v-slot:header>
	        <i class="fa-regular fa-file-plus"></i> <strong>{{ formHeader }}</strong>
	    </template>
		<b-card-body>
		    <validation-observer ref="observer" v-slot="{ passes }">
		    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
		    	<validation-provider vid="name" v-slot="{ errors }">
                    <b-input-group>
                    <b-form-input id="name" :state="errors[0] ? false : null" v-model="form.name" autocomplete="off" placeholder="Set template name..." />
                    <template #append>
		                <b-overlay
		                    :show="$store.getters['loading/isLoading']('test-template')"
		                    rounded
		                    opacity="0.6"
		                    spinner-small
		                    spinner-variant="success">
	                		<b-button :disabled="!hasTests || $store.getters['loading/isLoading']('test-template')" type="submit" :variant="activeTemplate ? 'warning' : 'success'" class="px-4" tabindex="3">{{ activeTemplate ? 'Update' : 'Save'}}</b-button>
	            		</b-overlay>
                    	</template>
                    </b-input-group>
                </validation-provider>
            </b-form>
        	</validation-observer>
    	</b-card-body>
        <template v-if="hasTests">
        <div class="scrollable-test-list">
            <b-list-group flush>
                <TemplateFormItem v-for="item in filterItems" :test="item" :key="item.id" @remove="onRemoveTest"/>
            </b-list-group>
        </div>
        </template>
        <template v-else>
            <b-list-group flush>
                <b-list-group-item class="text-center text-muted">No tests selected</b-list-group-item>
            </b-list-group>
        </template>
	</b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapGetters, mapState, mapActions } from 'vuex'

import TemplateFormItem from './TemplateFormItem'

export default ComponentExtension.extend({
	name: "lab-template-form",
    components: {
        TemplateFormItem
    },
    data() {
        return {
            selected: [],
            items: [],
            form: new Form({
                name: "",
                type_id: null,
                tests: [],
                consistency_token: null
            }),

            activeTemplate: null
        }
    },
    computed: {
        hasfilteredItems() {
            return this.selected.length > 0
        },
        filterItems() {
            return this.items.filter(item => this.selected.includes(item.id))
        },
        hasTests() {
            return this.selected.length > 0
        },
        formHeader() {
            return this.form.name || 'New Template'
        },
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
            }
        },
        // activeTemplate: {
        //     immediate: true,
        //     handler(val) {
        //         if(val){
        //             this.selected = Array.from(val.tests)
        //             this.form.populate(val)
        //             this.$emit('input', val.tests)
        //             this.$emit("update:typeId", val.type.id);
        //             this.$emit('change', val.type.id)
        //         }else{
        //             this.reset()
        //         }
        //     }
        // },
    },
    methods: {
        ...mapActions('lab', [
            'filterDiagnosticsTests',
            'createDiagnosticsTemplate', 
            'updateDiagnosticsTemplate', 
        ]),
        onRemoveTest(id) {
            this.selected = this.selected.filter(item => item !== id)
            this.form.populate({ tests: this.selected })
            // this.$emit('input', this.selected)

            EventBus.$emit('lab-update-tests-from-template-form', this.selected)
        },
        onFormSubmit() {
            if(this.activeTemplate) {
                this.updateDiagnosticsTemplate({id: this.activeTemplate.id, data: this.form.data()}).then(() => {
                    this.reset()
                    this.notify("Diagnostic referral template updated", "Diagnostic referral template was updated successfully")

                    EventBus.$emit('lab-get-templates')
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                        this.hasErrors = true
                    }
                })
            }else{
                this.createDiagnosticsTemplate(this.form.data()).then(() => {
                    this.reset()
                    this.notify("Diagnostic referral template created", "Diagnostic referral template was created successfully")

                    EventBus.$emit('lab-get-templates')
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                        this.hasErrors = true
                    }
                })
            }
        },
        loadFromSavedTemplate(val) {
            this.activeTemplate = val
            this.prepareTemplate(val)
        },
        prepareTemplate(val) {
            if(val){
                this.selected = Array.from(val.tests)
                this.form.populate(val)
            }else{
                this.reset()
            }
        },
        reset() {
            this.form.reset()
            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.selected = []
                this.activeTemplate = null
                this.validated()
                this.$emit('input', [])
            })
        }
    },
    mounted() {
        EventBus.$on('lab-template-loaded-from-template', (template) => {
            this.loadFromSavedTemplate(template)
        })

        EventBus.$on('lab-prepare-template', (data) => {
            this.prepareTemplate(data)
        })

        EventBus.$on('lab-reset-template-form', () => {
            this.reset()
        })
    },
    async created() {
        this.items = await this.filterDiagnosticsTests()
    },
    beforeDestroy() {
         EventBus.$off("lab-template-loaded-from-template")
         EventBus.$off("lab-prepare-template")
         EventBus.$off("lab-reset-template-form")
    }
})
</script>

<style scoped>
.scrollable-test-list {
/*    overflow-y: scroll;
    max-height: 180px;*/
}

</style>