<template>
    <b-modal body-class="p-0" :return-focus="target" size="lg" title="Write Referral" v-model="showModal" no-close-on-esc no-close-on-backdrop modal-class="modal-info" ok-variant="info" ok-title="Done" @ok="onOk" cancel-title="Dismiss" @hidden="onHidden">
        <ck-editor v-model="editorData" :config="editorConfig"/>
    </b-modal>
</template>
<script>
import Vue from 'vue'
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapState, mapGetters, mapActions } from "vuex"

import FileSaveModal from '@/core/components/general/filemanager/FileSaveModal'
import FileOpenModal from '@/core/components/general/filemanager/FileOpenModal'

export default ModalExtension.extend({
    name: 'referral-reason-modal',
    props: ['target', 'reason'],
    data() {
        return {
            editorData: '',
            editorConfig: {
                toolbar: {
                    removeItems: [ 'placeholder' ]
                },
                menuBar: {
                    removeItems: [ 'menuBar:new', 'menuBar:save', 'menuBar:saveAs', 'menuBar:sourceEditing', 'menuBar:pdf', 'menuBar:word', 'menuBar:print', 'menuBar:preview', ]
                },
                fileMenu: {
                    open: this.openFile
                },
            },
        }
    },
    watch: {
        reason: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.editorData = val
                }
            }
        }
    },
    methods: {
        ...mapActions('letters', ['templateToHTMLById']),
        onOk(evt) {
            evt.preventDefault()
            this.$emit('done', this.editorData)
            this.hide()
        },
        openFile( editor ) {
            // const action = this.createPendingAction(editor, 'waiting...')

            var ComponentClass = Vue.extend(FileOpenModal)
            var instance = new ComponentClass({
                parent: this,
                propsData: { title: 'Templates', showBuilderButton: false, categoryCode: 'referrals' }
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                // this.stopPendingAction(editor, action)
            })

            instance.$on("selected", (selected) => {
                this.open(selected)
            })

            instance.$mount()
        },
        async open(selected) {
            const referral = await this.templateToHTMLById(selected.id)
            this.editorData = referral
        },
    },
})
</script>