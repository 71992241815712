import { Plugin } from '@ckeditor/ckeditor5-core'

export default class VariablesPrescription extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'VariablesPrescription';
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;
        const plugin = editor.plugins.get('Variables');
        plugin.addItems('Prescription', [
            { character: 'prescriptions_chronic', title: t('Chronic Prescriptions')},
            { character: 'prescriptions', title: t('Prescriptions')},
        ], { label: t('Prescription') });
    }
}
