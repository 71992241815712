<template>
<b-card no-body>
    <template v-slot:header>
        <i class="fa-solid fa-star"></i> Saved Templates
    </template>
    <b-list-group flush>
        <b-list-group-item class="text-center text-muted">
            <b-input-group>
                <b-form-input placeholder="Search..." v-model="filterQuery"/>
                <template #append>
                    <b-button v-if="filterQuery" variant="warning" @click="filterQuery = ''"><i class="fa-regular fa-arrows-rotate-reverse"></i> Clear</b-button>
                </template>
            </b-input-group>
        </b-list-group-item>
        <template v-if="hasTemplates">
            <TemplateItem v-for="(item, index) in filteredItems" :item="item" :key="item?.id" :is-active="activeTemplate === item.id" @deleted="onDeleted" @selected="onactive"/>
        </template>
        <template v-else>
            <b-list-group-item class="text-center text-muted">No templates</b-list-group-item>
        </template>
    </b-list-group>
</b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapGetters, mapState, mapActions } from 'vuex'
import TemplateItem from './TemplateItem'

import { debounce } from 'lodash'

export default ComponentExtension.extend({
    name: "lab-templates-saved",
    components: {
        TemplateItem,
    },
    data() {
        return {
            filterQuery: "",
            items: [],
            activeTemplate: null
        }
    },
    computed: {
        hasTemplates() {
            return this.filteredItems.length > 0
        },
        filteredItems(){
            let initial = Array.from(this.items)
            return initial.length >= 10 ? initial.slice(0, 10) : [...initial]
        }
    },
    watch: {
        filterQuery: {
            handler() {
                this.lookupItem()
            }
        },
    },
    methods: {
        ...mapActions('lab', ['filterDiagnosticsTemplates', 'deleteDiagnosticsTemplate']),
        onactive(template, templateId) {
            this.activeTemplate = this.activeTemplate === templateId ? null : templateId
            const data = this.activeTemplate ? _.cloneDeep(template) : null
            EventBus.$emit('lab-copied-from-template', data)
            EventBus.$emit('lab-template-loaded-from-template', data)
        },
        onDeleted(id) {
            this.deleteDiagnosticsTemplate(id).then(() => {
                this.notify("Diagnostic referral template removed", "Diagnostic referral template was removed successfully")

                EventBus.$emit('lab-reset-template-form')
            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
            })
            .finally(() => {
                this.reset()
                this.lookupItem()
            })
        },
        // lookupItem: debounce(function () {
        //     this.filterDiagnosticsTemplates({ search: this.filterQuery })
        //         .then((items) => {
        //         const initial = Array.from(items)
        //         this.filteredItems = initial.length >= 10 ? initial.slice(0, 10) : [...initial]
        //     })
        // }, 100),

        lookupItem() {
            this.filterDiagnosticsTemplates({ search: this.filterQuery })
                .then((results) => {
                    this.items = results
            })
        },
        reset() {
            this.activeTemplate = null
            // this.filterQuery = ""
        },
        fullReset() {
            this.reset()
            this.filterQuery = ""
        }
    },
    async mounted() {
        this.items = await this.filterDiagnosticsTemplates()

        EventBus.$on('lab-get-templates', () => {
            this.lookupItem()
        })

        EventBus.$on('lab-reset-active-template', () => {
            this.reset()
        })
    },
    beforeDestroy() {
         EventBus.$off("lab-get-templates")
         EventBus.$off("lab-reset-active-template")
    }
})
</script>