<template>
    <b-card no-body class="card-accent-info rounded-0 border-left-0 border-right-0 mb-0 bg-gradient-light">
        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
            <b-tabs pills card content-class="border-left-0 border-bottom-0 border-right-0" active-nav-item-class="bg-info">
                <template #tabs-end>
                    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" class="ml-auto mr-1" @click="reset">
                        <i class="fa-solid fa-ban"></i> Reset
                    </b-button>
                    <b-button-group>
                    <b-button v-show="isDirty" type="submit" variant="success">
                        <!-- <i class="fa-regular fa-circle-dot fa-beat"></i> Generate Referral Letter -->
                        <i class="fa-regular fa-circle-dot fa-beat"></i> Save
                    </b-button>
                    <b-button v-show="isDirty" type="submit" variant="success" @click="saveAdnGenerate = true">
                        <!-- <i class="fa-regular fa-circle-dot fa-beat"></i> Generate Referral Letter -->
                        <i class="fa-regular fa-circle-dot fa-beat"></i> Save and Generate
                    </b-button>
                    </b-button-group>
                </template>
                <b-tab active>
                    <template slot="title">
                        <i class="fa-sharp fa-solid fa-forward"></i> Referral
                    </template>
                    <b-overlay :show="confirm" no-wrap @shown="onShown">
                        <template #overlay>
                            <div v-if="$store.getters['loading/isLoading']('referrals')" class="d-flex align-items-center">
                                <strong>Updating...</strong>
                                <b-spinner class="ml-auto"></b-spinner>
                            </div>
                            <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                                <p class="text-center">
                                    <i class="fa-solid fa-cloud-arrow-up font-sm text-success"></i> <strong class="text-success">CREATE REFERRAL ? </strong>
                                </p>
                                <div class="text-center">
                                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                                    <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                                </div>
                            </div>
                        </template>
                    </b-overlay>
                    <validation-provider vid="priority_id" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="3"
                        label="Referral Priority"
                        label-for="priority_id"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-select id="priority_id" name="priority_id" plain v-model="form.priority_id">
                            <b-form-select-option :value="null">* Select Referral Priority</b-form-select-option>
                            <b-form-select-option v-for="priority in referral_priorities" :key="priority.id" :value="priority.id">{{ priority.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    </validation-provider>
                    <validation-provider vid="type_id" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="3"
                        label="Referral Type"
                        label-for="type_id"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                             <b-input-group>
                                <b-form-select id="type_id" name="type_id" plain v-model="form.type_id">
                                    <b-form-select-option :value="null">* Select Referral Type</b-form-select-option>
                                    <b-form-select-option v-for="referral_type in referral_types" :key="referral_type.id" :value="referral_type.id">{{ referral_type.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            <b-form-input id="receiving" name="receiving" v-model="form.receiving" placeholder="Receiving provider..." autocomplete="off" />
                            </b-input-group>
                            <validation-provider vid="receiving" v-slot="{ errors }">
                                <b-form-invalid-feedback :state="errors[0] ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </validation-provider>

                    <b-form-group label="Consults" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                        v-model="form.consults"
                        :options="consultOptions"
                        :aria-describedby="ariaDescribedby"
                        name="radio-inline"
                        ></b-form-radio-group>
                    </b-form-group>
                    <validation-provider vid="reason" v-slot="{ errors }">
                        <b-form-group
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-button
                                block
                                type="button"
                                :variant="errors[0] ? 'danger' : (form.reason ? 'success' : 'primary')"
                                @click="showReferralReasonModal"
                              >
                                <i class="fa-regular" :class="form.reason ? 'fa-check' : 'fa-edit'"></i>
                                {{ form.reason ? 'Edit reason for referral...' : 'Describe the reason for referral...' }}
                              </b-button>
                        </b-form-group>
                    </validation-provider>
                    <b-form-group
                        label="Most Recent:">
                        <b-list-group>
                            <template v-if="hasReferrals">
                            <b-list-group-item class="p-0 flex-column align-items-start" v-for="referral in referrals" :key="`referral-${referral.id}`">
                                <div class="ml-2 p-2">
                                    <div class="d-flex w-100 align-items-center justify-content-between">
                                        <h6 class="m-0">{{ referral.type.name }} - {{ referral.priority.name }}</h6>
                                        <small>{{ toLocaleDate(referral.created) }}</small>
                                        <b-button size="sm" v-if="checkUserPermission('referral', 'can_print') && referral.printable" variant="secondary" @click="print(referral)"><i class="fa-solid fa-print"></i></b-button> 
                                    </div>
                                </div>
                            </b-list-group-item>
                            </template>
                            <template v-if="hasReferrals">
                                <b-list-group-item href="#" @click="showReferralModal">Show more</b-list-group-item>
                            </template>
                        </b-list-group>
                    </b-form-group>
                </b-tab>
            </b-tabs>
            </b-form>
        </validation-observer>
    </b-card>
</template>
<script>
import Vue from 'vue'
import FormExtension from '@/core/components/FormExtension'
import { mapGetters, mapActions, mapState } from 'vuex'

import ReferralModal from './ReferralModal'
import ReferralReasonModal from './ReferralReasonModal'
import printJS from 'print-js'

export default FormExtension.extend({
    name: 'decursus-referral',
    props: ['limit'],
    data() {
        return {
            form: new Form({
                type_id: null,
                consults: 1,
                priority_id: null,
                receiving: "",
                reason: "",
                consistency_token: null
            }),

            confirm: false,

            minDate: new Date(),

            saveAdnGenerate: false
        }
    },
    computed: {
        ...mapGetters('referrals', ['getPatientReferrals']),
        referrals() {
            return this.getPatientReferrals().slice(0, this.limit)
        },
        hasReferrals() {
            return this.referrals.length > 0
        },
        ...mapState('catalogue', {
            referral_types: state => state.referral_types,
            referral_priorities: state => state.referral_priorities,
        }),

        consultOptions() {
            return [
                { text: '1', value: '1' },
                { text: '2', value: '2' },
            ]
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('referrals', ['getReferralsByPatientId', 'createReferral', 'referralPrintableById']),
        onFormSubmit() {
            this.confirm = true
        },
        onConfirmed() {
            this.createReferral(this.form.data()).then((referral) => {
                this.notify("Referral created", "Patient referral was created successfully")
                this.confirm = false
                if (this.saveAdnGenerate) {
                    this.print(referral)
                }

                this.reset()

            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                    this.confirm = false
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
        },
        reset() {
            this.form.reset()
            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.saveAdnGenerate = false
            })
        },
        showReferralModal() {
            var ComponentClass = Vue.extend(ReferralModal)
            var instance = new ComponentClass({ parent: this, propsData: { current: this.referral } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$mount()
        },
        showReferralReasonModal(evt) {
            const form = this.form.only(['reason'])
            var ComponentClass = Vue.extend(ReferralReasonModal)
            var instance = new ComponentClass({ parent: this, propsData: { target: evt.target, reason: form.reason } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$on("done", (reason) => {
                this.form.populate({ reason: reason})
                this.$refs.observer.validate()
            })

            instance.$mount()
        },
        async print(item) {
            const referral = await this.referralPrintableById(item.id)
            printJS({
                printable: referral,
                type: 'pdf',
                base64: true
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('referral_types')
        await this.getCatalogueItem('referral_priorities')
        await this.getReferralsByPatientId()
    }
})
</script>