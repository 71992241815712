<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

    <validation-provider vid="non_field_errors" v-slot="{ errors }">
      <b-form-invalid-feedback :state="errors[0] ? false : null">
        <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
      </b-form-invalid-feedback>
    </validation-provider>

    <validation-provider name="Temperature" vid="temperature" rules="measurement" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Temperature"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="Celsius">
                    <b-form-input type="number" id="temperature" v-comma2dot name="temperature" placeholder=".00" v-model="form.temperature"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="temperature_method_id" name="temperature_method_id" plain v-model="form.temperature_method_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="temperature_method in body_temperature_measurement_methods" :key="temperature_method.id" :value="temperature_method.id">{{ temperature_method.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>

    <validation-provider vid="heart_rhythm" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Hearth Rhythm"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                <b-form-select id="heart_rhythm_id" name="heart_rhythm_id" plain v-model="form.heart_rhythm_id">
                    <b-form-select-option :value="null">Select Rhythm</b-form-select-option>
                    <b-form-select-option v-for="heart_rhythm in heart_rhythms" :key="heart_rhythm.id" :value="heart_rhythm.id">{{ heart_rhythm.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="heart_rhythm_type_id" name="heart_rhythm_type_id" plain v-model="form.heart_rhythm_type_id">
                    <b-form-select-option :value="null">Select Type</b-form-select-option>
                    <b-form-select-option v-for="heart_rhythm_type in heart_rhythm_measurement_types" :key="heart_rhythm_type.id" :value="heart_rhythm_type.id">{{ heart_rhythm_type.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>

    <validation-provider name="Pulse rate" vid="pulse_rate" rules="numeric" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Pulse Rate"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="beats per minute">
                    <b-form-input type="number" id="pulse_rate" v-comma2dot name="pulse_rate" placeholder="000" v-model="form.pulse_rate"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="pulse_strength_id" name="pulse_strength_id" plain v-model="form.pulse_strength_id">
                    <b-form-select-option :value="null">Select Strength</b-form-select-option>
                    <b-form-select-option v-for="pulse_strength in pulse_strengths" :key="pulse_strength.id" :value="pulse_strength.id">{{ pulse_strength.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="pulse_rate_method_id" name="pulse_rate_method_id" plain v-model="form.pulse_rate_method_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="pulse_rate_method in pulse_rate_measurement_methods" :key="pulse_rate_method.id" :value="pulse_rate_method.id">{{ pulse_rate_method.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>
    <validation-provider name="Respiration rate" vid="respiration_rate" rules="numeric" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Respiration"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="breaths per minute">
                    <b-form-input type="number" id="respiration_rate" v-comma2dot name="respiration_rate" placeholder="000" v-model="form.respiration_rate"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="respiration_rhythm_id" name="respiration_rhythm_id" plain v-model="form.respiration_rhythm_id">
                    <b-form-select-option :value="null">Select Rhythm</b-form-select-option>
                    <b-form-select-option v-for="respiration_rhythm in respiration_rhythms" :key="respiration_rhythm.id" :value="respiration_rhythm.id">{{ respiration_rhythm.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="respiration_type_id" name="respiration_type_id" plain v-model="form.respiration_type_id">
                    <b-form-select-option :value="null">Select Type</b-form-select-option>
                    <b-form-select-option v-for="respiration_type in respiration_measurement_types" :key="respiration_type.id" :value="respiration_type.id">{{ respiration_type.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>
    <validation-provider name="Blood pressure systolic" vid="blood_pressure_systolic" rules="numeric" v-slot="{ errors }">

    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Blood pressure (systolic)"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="mm Hg">
                    <b-form-input type="number" id="blood_pressure_systolic" v-comma2dot name="blood_pressure_systolic" placeholder="000" v-model="form.blood_pressure_systolic"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_systolic_method_id" name="blood_pressure_systolic_method_id" plain v-model="form.blood_pressure_systolic_method_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="systolic_method in systolic_measurement_methods" :key="systolic_method.id" :value="systolic_method.id">{{ systolic_method.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_side_id" name="blood_pressure_side_id" plain v-model="form.blood_pressure_side_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="blood_pressure_side in blood_pressure_sides" :key="blood_pressure_side.id" :value="blood_pressure_side.id">{{ blood_pressure_side.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>
    <validation-provider name="Blood pressure diastolic" vid="blood_pressure_diastolic" rules="numeric" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Blood pressure (diastolic)"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="mm Hg">
                    <b-form-input type="number" id="blood_pressure_diastolic" v-comma2dot name="blood_pressure_diastolic" placeholder="000" v-model="form.blood_pressure_diastolic"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_diastolic_method_id" name="blood_pressure_diastolic_method_id" plain v-model="form.blood_pressure_diastolic_method_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="diastolic_method in diastolic_measurement_methods" :key="diastolic_method.id" :value="diastolic_method.id">{{ diastolic_method.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_side_id" name="blood_pressure_side_id" plain v-model="form.blood_pressure_side_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="blood_pressure_side in blood_pressure_sides" :key="blood_pressure_side.id" :value="blood_pressure_side.id">{{ blood_pressure_side.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>

    <!-- BLOOD PRESSURE 2 -->

    <validation-provider name="Blood pressure 2 systolic" vid="blood_pressure_2_systolic" rules="numeric" v-slot="{ errors }">

    <b-form-group
        :disabled="noFirstBloodPressureSystolic"
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Blood pressure 2 (systolic)"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="mm Hg">
                    <b-form-input type="number" id="blood_pressure_2_systolic" v-comma2dot name="blood_pressure_2_systolic" placeholder="000" v-model="form.blood_pressure_2_systolic"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_2_systolic_method_id" name="blood_pressure_2_systolic_method_id" plain v-model="form.blood_pressure_2_systolic_method_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="systolic_method in systolic_measurement_methods" :key="systolic_method.id" :value="systolic_method.id">{{ systolic_method.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_2_side_id" name="blood_pressure_2_side_id" plain v-model="form.blood_pressure_2_side_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="blood_pressure_side in blood_pressure_sides" :key="blood_pressure_side.id" :value="blood_pressure_side.id">{{ blood_pressure_side.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>

    <validation-provider name="Blood pressure 2 diastolic" vid="blood_pressure_2_diastolic" rules="numeric" v-slot="{ errors }">
    <b-form-group
        :disabled="noFirstBloodPressureDiastolic"
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Blood pressure 2(diastolic)"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                 <b-input-group append="mm Hg">
                    <b-form-input type="number" id="blood_pressure_2_diastolic" v-comma2dot name="blood_pressure_2_diastolic" placeholder="000" v-model="form.blood_pressure_2_diastolic"  />
                </b-input-group>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_2_diastolic_method_id" name="blood_pressure_2_diastolic_method_id" plain v-model="form.blood_pressure_2_diastolic_method_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="diastolic_method in diastolic_measurement_methods" :key="diastolic_method.id" :value="diastolic_method.id">{{ diastolic_method.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select id="blood_pressure_2_side_id" name="blood_pressure_2_side_id" plain v-model="form.blood_pressure_2_side_id">
                    <b-form-select-option :value="null">Select Method</b-form-select-option>
                    <b-form-select-option v-for="blood_pressure_side in blood_pressure_sides" :key="blood_pressure_side.id" :value="blood_pressure_side.id">{{ blood_pressure_side.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>

    <validation-provider vid="palpation_location" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Palpation Location"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-row>
            <b-col>
                <b-form-select id="palpation_location_id" name="palpation_location_id" plain v-model="form.palpation_location_id">
                    <b-form-select-option :value="null">Select Location</b-form-select-option>
                    <b-form-select-option v-for="palpation_location in palpation_locations" :key="palpation_location.id" :value="palpation_location.id">{{ palpation_location.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-form-row>
    </b-form-group>
    </validation-provider>
    <validation-provider name="Saturation" vid="saturation" rules="measurement" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Saturation"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
            <b-form-input type="number" id="saturation" v-comma2dot name="saturation" placeholder=".00" v-model="form.saturation"  />
    </b-form-group>
    </validation-provider>
    <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

    </b-form>
    </validation-observer>
</b-card>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
    name: "vital-functions-form",
    data() {
        return {
            form: null
        }
    },
    computed: {
        ...mapState('catalogue', {
            body_temperature_measurement_methods: state => state.body_temperature_measurement_methods,
            pulse_rate_measurement_methods: state => state.pulse_rate_measurement_methods,
            heart_rhythms: state => state.heart_rhythms,
            pulse_strengths: state => state.pulse_strengths,
            heart_rhythm_measurement_types: state => state.heart_rhythm_measurement_types,
            palpation_locations: state => state.palpation_locations,
            respiration_measurement_types: state => state.respiration_measurement_types,
            respiration_rhythms: state => state.respiration_rhythms,
            systolic_measurement_methods: state => state.systolic_measurement_methods,
            diastolic_measurement_methods: state => state.diastolic_measurement_methods,
            blood_pressure_sides: state => state.blood_pressure_sides
        }),

        noFirstBloodPressureSystolic() {
            return this.form.blood_pressure_systolic == null
        },
        noFirstBloodPressureDiastolic() {
            return this.form.blood_pressure_diastolic == null
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        onFormSubmit() {
            this.callback(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient vitals created", "Patient vitals was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                heart_rhythm_id: null,
                heart_rhythm_type_id: defaults?.heart_rhythm_measurement_type,
                palpation_location_id: null,
                temperature: null,
                temperature_method_id: defaults?.body_temperature_measurement_method,
                pulse_rate: null,
                pulse_strength_id: null,
                pulse_rate_method_id: defaults?.pulse_rate_measurement_method,
                respiration_rate: null,
                respiration_type_id: defaults?.respiration_measurement_type,
                respiration_rhythm_id: null,
                blood_pressure_systolic: null,
                blood_pressure_systolic_method_id: defaults?.systolic_measurement_method,
                blood_pressure_diastolic: null,
                blood_pressure_diastolic_method_id: defaults?.diastolic_measurement_method,
                blood_pressure_side_id: defaults?.blood_pressure_side,
                blood_pressure_2_systolic: null,
                blood_pressure_2_systolic_method_id: defaults?.systolic_measurement_method,
                blood_pressure_2_diastolic: null,
                blood_pressure_2_diastolic_method_id: defaults?.diastolic_measurement_method,
                blood_pressure_2_side_id: defaults?.blood_pressure_side,

                saturation: null,
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('body_temperature_measurement_methods')
        await this.getCatalogueItem('pulse_rate_measurement_methods')
        await this.getCatalogueItem('heart_rhythms')
        await this.getCatalogueItem('pulse_strengths')
        await this.getCatalogueItem('heart_rhythm_measurement_types')
        await this.getCatalogueItem('palpation_locations')
        await this.getCatalogueItem('respiration_measurement_types')
        await this.getCatalogueItem('respiration_rhythms')
        await this.getCatalogueItem('systolic_measurement_methods')
        await this.getCatalogueItem('diastolic_measurement_methods')
        await this.getCatalogueItem('blood_pressure_sides')
    }
})
</script>