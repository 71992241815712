<template>
	<div id="corner-popup">
        <slot></slot>
	</div>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
export default ComponentExtension.extend({
	name: "corner-popup",
})
</script>

<style scoped>
#corner-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1030; /* Lower it to allow page interaction */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.scrollable-test-list {
    overflow-y: scroll;
    max-height: 430px;
}
</style>