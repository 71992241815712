import { Plugin } from '@ckeditor/ckeditor5-core'

export default class VariablesPatient extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'VariablesPatient';
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;
        const plugin = editor.plugins.get('Variables');
        plugin.addItems('Patient', [
            { character: 'internal_number', title: t('Internal number')},
            { character: 'title', title: t('Title')},
            { character: 'full_name', title: t('Full name')},
            { character: 'first_name', title: t('First name')},
            { character: 'surname', title: t('Surname')},
            { character: 'date_of_birth', title: t('Date of birth')},
            { character: 'birth_place', title: t('Birth place')},
            { character: 'biological_gender', title: t('Biological gender')},
            { character: 'identified_gender', title: t('Identified gender')},
            { character: 'ethnicity', title: t('Ethnicity')},
            { character: 'proclaimed_ethnicity', title: t('Proclaimed ethnicity')},
            { character: 'nationality', title: t('Nationality')},
            { character: 'marital_status', title: t('Marital status')},
            { character: 'address', title: t('Address')},
            { character: 'blood_type', title: t('Blood type')},
            { character: 'allergies', title: t('Allergies')},
            { character: 'history', title: t('History')},
        ], { label: t('Patient') });
    }
}
