<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
    
    <b-overlay :show="confirm" no-wrap @shown="onShown">
        <template #overlay>
            <div v-if="$store.getters['loading/isLoading']('referrals')" class="d-flex align-items-center">
                <strong>Updating...</strong>
                <b-spinner class="ml-auto"></b-spinner>
            </div>
            <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                <p class="text-center">
                    <i class="fa-solid fa-cloud-arrow-up font-sm text-success"></i> <strong class="text-success">CREATE REFERRAL ? </strong>
                </p>
                <div class="text-center">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                    <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                </div>
            </div>
        </template>
    </b-overlay>

    <validation-provider vid="non_field_errors" v-slot="{ errors }">
      <b-form-invalid-feedback :state="errors[0] ? false : null">
        <h6 class="d-block p-3 bg-danger">{{ errors[0] }}</h6>
      </b-form-invalid-feedback>
    </validation-provider>
    
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Decursus"
        label-for="decursus_id">
        <b-form-select id="decursus_id" name="decursus_id" plain v-model="form.decursus_id">
            <b-form-select-option :value="null">* Select Decursus (Leave blank to create new Decursus)</b-form-select-option>
            <b-form-select-option v-for="decursus_item in decursus" :key="decursus_item.id" :value="decursus_item.id">{{ toLocaleDate(decursus_item.date) }}
            </b-form-select-option>
        </b-form-select>
    </b-form-group>

    <validation-provider vid="priority_id" v-slot="{ errors }">
        <b-form-group
            :label-size="small ? 'sm' : 'md'"
            label-cols-sm="2"
            label-cols-lg="3"
            label="Referral Priority"
            label-for="priority_id"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <b-form-select id="priority_id" name="priority_id" plain v-model="form.priority_id">
                <b-form-select-option :value="null">* Select Referral Priority</b-form-select-option>
                <b-form-select-option v-for="priority in referral_priorities" :key="priority.id" :value="priority.id">{{ priority.name }}
                </b-form-select-option>
            </b-form-select>
        </b-form-group>
        </validation-provider>
        <validation-provider vid="type_id" v-slot="{ errors }">
        <b-form-group
            :label-size="small ? 'sm' : 'md'"
            label-cols-sm="2"
            label-cols-lg="3"
            label="Referral Type"
            label-for="type_id"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
                 <b-input-group>
                    <b-form-select id="type_id" name="type_id" plain v-model="form.type_id">
                        <b-form-select-option :value="null">* Select Referral Type</b-form-select-option>
                        <b-form-select-option v-for="referral_type in referral_types" :key="referral_type.id" :value="referral_type.id">{{ referral_type.name }}
                        </b-form-select-option>
                    </b-form-select>
                <b-form-input id="receiving" name="receiving" v-model="form.receiving" placeholder="Receiving provider..." autocomplete="off" />
                </b-input-group>
                <validation-provider vid="receiving" v-slot="{ errors }">
                    <b-form-invalid-feedback :state="errors[0] ? false : null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>
        </validation-provider>

        <b-form-group
            :label-size="small ? 'sm' : 'md'"
            label-cols-sm="2"
            label-cols-lg="3"
            label="Consults"
            label-for="type_id"
            v-slot="{ ariaDescribedby }"
            >
            <b-form-radio-group
            v-model="form.consults"
            :options="consultOptions"
            :aria-describedby="ariaDescribedby"
            name="radio-inline"
            ></b-form-radio-group>
        </b-form-group>
        <validation-provider vid="reason" v-slot="{ errors }">
            <b-form-group
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-button
                    block
                    type="button"
                    :variant="errors[0] ? 'danger' : (form.reason ? 'success' : 'primary')"
                    @click="showReferralReasonModal"
                  >
                    <i class="fa-regular" :class="form.reason ? 'fa-check' : 'fa-edit'"></i>
                    {{ form.reason ? 'Edit reason for referral...' : 'Describe the reason for referral...' }}
                  </b-button>
            </b-form-group>
        </validation-provider>

        <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
            
    </b-form>
    </validation-observer>
</b-card>
</template>
<script>
import Vue from 'vue'
import FormExtension from '@/core/components/FormExtension'
import { mapGetters, mapActions, mapState } from 'vuex'

import ReferralReasonModal from './ReferralReasonModal'

export default FormExtension.extend({
    name: 'decursus-referral',
    props: ['limit'],
    data() {
        return {
            form: new Form({
                type_id: null,
                consults: 1,
                priority_id: null,
                receiving: "",
                reason: "",
                decursus_id: null,
                consistency_token: null
            }),

            confirm: false,
        }
    },
    computed: {
        ...mapState('catalogue', {
            referral_types: state => state.referral_types,
            referral_priorities: state => state.referral_priorities,
        }),
        ...mapGetters('decursus', ['getPatientDecursus']),
        decursus() {
            return this.getPatientDecursus()
        },
        consultOptions() {
            return [
                { text: '1', value: '1' },
                { text: '2', value: '2' },
            ]
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('referrals', ['referralPrintableById']),
        ...mapActions('decursus', ['getDecursusByPatientId']),
        onFormSubmit() {
            this.confirm = true
        },
        onConfirmed() {
            this.callback(this.form.data()).then((referral) => {
                this.reset()
                this.notify("Referral created", "Patient referral was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                    this.confirm = false
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
        },
        reset() {
            this.form.reset()
            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.confirm = false
                this.saveAdnGenerate = false
            })
        },
        showReferralReasonModal(evt) {
            const form = this.form.only(['reason'])
            var ComponentClass = Vue.extend(ReferralReasonModal)
            var instance = new ComponentClass({ parent: this, propsData: { target: evt.target, reason: form.reason } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$on("done", (reason) => {
                this.form.populate({ reason: reason})
                this.$refs.observer.validate()
            })

            instance.$mount()
        }
    },
    async mounted() {
        await this.getCatalogueItem('referral_types')
        await this.getCatalogueItem('referral_priorities')
        await this.getDecursusByPatientId()
    }
})
</script>