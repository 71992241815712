<template>
    <b-card class="card-accent-danger" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-stethoscope"></i> <strong> Vital Functions </strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <b-overlay 
            :show="$store.getters['loading/isLoading']('vitals')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    hover
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('vitals')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(temperature_full)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(heart_rhythm_full)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(pulse_rate_full)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(respiration_rate_full)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(blood_pressure_systolic_full)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(blood_pressure_diastolic_full)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(saturation)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value || 'n/a' }} </span>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>
                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="7" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
            </b-card-body>
        </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "reporting-vital-functions",
    data() {
        return {
            addNew: false,
            form: null,
            fields: [
                { key: 'created', label: 'Date/Time', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'temperature_full', label: 'Temperature', class: 'align-middle text-center' },
                { key: 'heart_rhythm_full', label: 'Heart Rhythm', class: 'align-middle text-center' },
                { key: 'pulse_rate_full', label: 'Pulse Rate', class: 'align-middle text-center' },
                { key: 'respiration_rate_full', label: 'Respiration Rate', class: 'align-middle text-center' },
                { key: 'blood_pressure_systolic_full', label: 'BP systolic', class: 'align-middle text-center' },
                { key: 'blood_pressure_diastolic_full', label: 'BP diastolic', class: 'align-middle text-center' },
                { key: 'saturation', label: 'Saturation', class: 'align-middle text-center' },                
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null
        }
    },
    computed: {
        ...mapGetters('health', ['getPatientVitals']),
        items() {
            return this.getPatientVitals()
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    methods: {
        ...mapActions('health', ['getVitalsByPatientId']),
    },
    async mounted() {
        await this.getVitalsByPatientId()

    }
})
</script>