<template>
	<b-modal body-class="p-0" scrollable size="md" :title="title" v-model="showModal" :ok-only="true" @ok="onOk" @hidden="onHidden" ok-title="Cancel" hide-header-close no-close-on-esc no-close-on-backdrop>
        <b-table
            ref="table"
            class="m-0"
            selectable
            select-mode="single"
            selected-variant="success"
            @row-selected="onRowSelected"
            responsive
            stacked="sm"
            show-empty
            striped
            borderless
            hover
            :items="templates"
            :fields="fields"
            :no-sort-reset="true"
            :no-border-collapse="false">

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template v-slot:empty="scope">
                <div class="small text-muted text-center my-2">
            		<b-button v-if="showBuilderButton" size="sm" type="button" variant="primary" @click="openBuilder"><i class="fa-solid fa-envelope-open-text"></i> Open Template Builder</b-button>
                    <span v-else>n/a</span>
                </div>
            </template>
        </b-table>
		
	</b-modal>
</template>

<script>
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default ModalExtension.extend({
	props: ['title', 'showBuilderButton', 'categoryCode'],
	data() {
		return {
			fields: [
				{ key: 'name', label: 'Name', class: 'align-middle'},
				{ key: 'created', label: 'Created', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
				{ key: 'updated', label: 'Updated', class: 'align-middle', formatter: (value) => value ? this.toLocaleDate(value) : 'n/a' }
			]
		}
	},
	computed: {
        ...mapGetters('letters', ['getTemplates']),
        templates() {
            return this.getTemplates().filter(item => item.category.code === this.categoryCode)
        }
	},
	methods: {
        ...mapActions('letters', ['getLetterTemplates']),
		onOk(evt) {
			evt.preventDefault()
			this.hide()
		},
		onRowSelected(row) {
			if (row[0]) {
				this.$emit('selected', row[0])
				this.hide()
			}
		},
		openBuilder() {
			this.$emit('open-builder')
			this.hide()
		}
	},
	async mounted() {
		await this.getLetterTemplates()
	}
})
</script>