import { Plugin } from '@ckeditor/ckeditor5-core'

export default class VariablesDecursus extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'VariablesDecursus';
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;
        const plugin = editor.plugins.get('Variables');
        plugin.addItems('Decursus', [
            { character: 'soep', title: t('SOEP')},
            { character: 'subjective', title: t('SOEP: Subjective')},
            { character: 'objective', title: t('SOEP: Objective')},
            { character: 'evaluation', title: t('SOEP: Evaluation')},
            { character: 'policy', title: t('SOEP: Policy')},
        ], { label: t('Decursus') });
    }
}
