<template>
<div :class="{'p-3': !administration }">
	
	<b-form-group v-if="checkUserPermission('medicalcard', 'can_add')" description="Select multiple files for simultaneous upload.">

        <b-input-group prepend="Upload Medical Cards">
			<b-form-file ref="fileUpload" multiple v-model="files" @input="onChange" :accept="formats"></b-form-file>
        </b-input-group>

	</b-form-group>

	<hr />

    <b-overlay 
	    :show="$store.getters['loading/isLoading']('medicalcards')"
	    rounded="sm"
	    variant="light"
	    :opacity="0.80"
	    blur="2px">

	<Columns :columns="small ? 4 : 5">
		<MedicalCard :card="card" v-for="(card, index) in cards" :key="`card-${card.id}`" />
	</Columns>
	</b-overlay>

	<template v-if="!checkUserPermission('medicalcard', 'can_add')">
		<b-form-group>
			<div v-if="!hasCards" class="d-flex justify-content-center">
			 	<span class="my-2">No Medical Cards uploaded</span>
			 </div>
		</b-form-group>
	</template>

	<b-form-group v-show="hasFiles">
		<b-progress height="20px" :max="maxFiles" animated>
			<b-progress-bar :value="uploadCount" variant="success">
				<span>Uploading: <strong>{{ uploadCount }} / {{ maxFiles }}</strong></span>
			</b-progress-bar>
		</b-progress>
	</b-form-group>
</div>
</template>
<script>
import ComponentBaseExtension from '@/core/components/ComponentBaseExtension'
import { mapState, mapGetters, mapActions } from "vuex"
import MedicalCard from "./MedicalCard"
import { Columns } from 'vue-columns'

export default ComponentBaseExtension.extend({
	name: "medical-cards",
    components: {
    	MedicalCard,
        Columns
    },
    props: {
    	patient: Object,
        administration: {
            type: Boolean,
            default: false
        }
    },
	data() {
		return {
			formats: ".jpeg, .jpg, .png, .pdf",
			files: [],
			uploadCount: 0
		}
	},
	computed: {
		maxFiles() {
			return this.files.length
		},
		hasFiles() {
			return this.files.length > 0
		},
        ...mapGetters('patients', ['getPatientMedicalCards']),
        cards() {
            return this.getPatientMedicalCards()
        },
        hasCards() {
        	return this.cards.length > 0
        }
	},
	methods: {
		...mapActions("patients", ["uploadMedicalCard", "deleteMedicalCard"]),
		onChange() {
			if (this.files.length > 0) {
				var promises = []
				this.files.forEach(file => { 
					this.uploadCount++
					const form = Form.create()
								.withData({
								        patient: this.patient.id,
								        document: file,
								        consistency_token: null,
								    })
				    promises.push(
						this.uploadMedicalCard(form.formData()).then(() => {
							setTimeout(function() {}, this.uploadCount * 100)
						})
			            .catch(errors => {
							this.notify('Card Error', 'Error uploading medical card', 'danger')
						})
				    )
				})

				Promise.all(promises).then(() => {
				    this.reset()
					this.notify('Cards Uploaded', 'Medical Cards uploaded')
				})
			}
		},
		reset() {
			this.uploadCount = 0
			this.files = []
		},
		deleteCard(id) {
			this.dialog("Delete Medical Card Scan", "Are you sure you want to permanently delete this scanned card?").then((result) => {
				if (result) {
					this.deleteMedicalCard(id).then(() => {
						this.notify('Card Deleted', `Medical Card deleted`)
					})
		            .catch(errors => {
		                this.notify('Card Error', 'Error deleting medical card', 'danger')
		            })
				}
			})
		},
        openCard(item) {
            if(item.document_extension === ".pdf") {
                window.open(item.document_url, '_blank')
            }else{
                this.$viewerApi({
                  images: [item.document_url],
                })
            }
        }
	}
})
</script>