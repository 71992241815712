<template>
    <b-card class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-flask-round-potion"></i> <strong>Diagnostic Referrals</strong>
            <div class="card-header-actions">
                <b-link  class="card-header-action" @click="showChart = !showChart">
                    <i class="fa-regular fa-chart-line"></i>
                </b-link>
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <diagnostic-referrals-base borderless :callback="createDiagnostics" />
        <diagnostic-referrals-chart v-if="showChart" @close="showChart = false" />
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import ReferralsBase from './ReferralsBase'
import Chart from './Chart'
import { mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "diagnostic-referrals",
    components: {
        'diagnostic-referrals-base': ReferralsBase,
        'diagnostic-referrals-chart': Chart
    },
    data() {
        return {
            showChart: false
        }
    },
    methods: {
        ...mapActions('lab', ['createDiagnostics']),
    }
})
</script>