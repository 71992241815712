<template>
	<b-modal body-class="p-0" scrollable size="lg" title="Open File.." v-model="showModal" @hidden="onHidden" :ok-only="true" ok-title="Dismiss" hide-header-close no-close-on-esc no-close-on-backdrop>
	<b-card no-body class="m-0 border-bottom-0 border-right-0 border-left-0 rounded-0">
		<b-tabs v-model="tabIndex" pills card vertical nav-wrapper-class="w-25" content-class="border-right-0 border-bottom-0 border-left-1 border-top-1">
			<template #tabs-end>
				<b-nav-item href="#" @click="activeTab(category)" no-body title-item-class="mb-1" class="p-0" v-for="category in categories" :key="category.id">{{ category.name }}</b-nav-item>
			</template>
	        <b-table
	            ref="table"
	            class="m-0"
	            selectable
	            select-mode="single"
	            selected-variant="success"
	            @row-selected="onRowSelected"
	            responsive
	            stacked="sm"
	            show-empty
	            striped
	            borderless
	            hover
	            :items="filteredTemplates"
	            :fields="fields"
	            :no-sort-reset="true"
	            :no-border-collapse="false">

	            <div slot="table-busy" class="text-center text-danger my-2">
	                <b-spinner class="align-middle"></b-spinner>
	                <strong>Loading...</strong>
	            </div>

	            <template v-slot:empty="scope">
	                <div class="small text-muted text-center my-2">
	                    <span>n/a</span>
	                </div>
	            </template>
	        </b-table>
		</b-tabs>
	</b-card>
	</b-modal>
</template>

<script>
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default ModalExtension.extend({
	data() {
		return {
			selectedCategory: 'uncategorized',
			fields: [
				{ key: 'name', label: 'Name', class: 'align-middle'},
				{ key: 'created', label: 'Created', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
				{ key: 'updated', label: 'Updated', class: 'align-middle', formatter: (value) => value ? this.toLocaleDate(value) : 'n/a' }
			],
			tabIndex: 0,
		}
	},
	computed: {
        ...mapGetters('letters', ['getTemplates', 'getCategories']),
        categories() {
            return this.getCategories()
        },
        templates() {
            return this.getTemplates()
        },
        filteredTemplates() {
        	// return this.templates.filter(item => item.category ?  item.category.id == this.form.category_id : item)
			return this.templates.filter(item => item.category.code === this.selectedCategory)
        },
	},
	methods: {
        ...mapActions('letters', ['getTemplateCategories', 'setCurrentTemplate']),
        activeTab(category) {
        	this.selectedCategory = category.code
        },
		onRowSelected(row) {
			if (row[0]) {
				this.$emit('selected', row[0])
				this.setCurrentTemplate(row[0])
				this.hide()
			}
		}
	},
	async mounted() {
		await this.getTemplateCategories()
	}
})
</script>