<template>
<b-tab title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Referrals
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">
        <diagnostic-referrals v-if="checkUserPermission('lab', 'can_view')"></diagnostic-referrals>
        <patient-referrals v-if="checkUserPermission('referral', 'can_view')"></patient-referrals>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import DiagnosticReferrals from "./referrals/DiagnosticReferrals"
import PatientReferrals from "./referrals/PatientReferrals"

export default TabExtension.extend({
    name: 'referrals',
    components: {
        'diagnostic-referrals': DiagnosticReferrals,
        'patient-referrals': PatientReferrals,
    }
})
</script>