<template>
	<b-modal body-class="p-0" scrollable size="lg" title="Save as" v-model="showModal" @hidden="onHidden" @ok="onOk" ok-title="Save" hide-header-close no-close-on-esc no-close-on-backdrop>
		<b-form class="p-3">
			<b-form-group
				class="m-0"
		        label="Save as"
		        label-for="save-as"
		        label-cols-sm="2"
		        label-cols-lg="3"
		        label-align-sm="right"
		      >
	        	<b-form-input id="save-as" v-model="file.name" placeholder="Save as..."></b-form-input>			
			</b-form-group>
		</b-form>
	<b-card no-body class="m-0 border-bottom-0 border-right-0 border-left-0 rounded-0">
        <b-table
            ref="table"
            class="m-0"
            selectable
            select-mode="single"
            selected-variant="success"
            @row-selected="onRowSelected"
            responsive
            stacked="sm"
            show-empty
            striped
            borderless
            hover
            :items="items"
            :fields="fields"
            :no-sort-reset="true"
            :no-border-collapse="false">

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template v-slot:empty="scope">
                <div class="small text-muted text-center my-2">
                    <span>n/a</span>
                </div>
            </template>
        </b-table>
	</b-card>
	</b-modal>
</template>

<script>
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default ModalExtension.extend({
	props: ['items', 'title'],
	data() {
		return {
			selectedFile: null,
            file: new Form({
                name: '',
                consistency_token: null,
            }),
			fields: [
				{ key: 'name', label: 'Name', class: 'align-middle'},
				{ key: 'created', label: 'Created', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
				{ key: 'updated', label: 'Updated', class: 'align-middle', formatter: (value) => value ? this.toLocaleDate(value) : 'n/a' }
			],
		}
	},
	methods: {
		onOk(evt) {
			evt.preventDefault()
			//update file
			if (this.selectedFile) {
				this.confirm(this.selectedFile.name)
			}else{
				// new file
				this.$emit('save', this.file.data())
				this.hide()
			}
		},
		confirm(name) {
			this.$bvModal
				.msgBoxConfirm(`"${name}" already exists. Do you want to replace it?`, {
					title: "Unsaved changes",
					size: "sm",
					buttonSize: "lg",
					okVariant: "danger",
					okTitle: "Overwrite",
					cancelTitle: "Cancel",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						this.$emit('save', this.file.data(), this.selectedFile)
						this.hide()
					}
				})
		},
		onRowSelected(row) {
			this.selectedFile = row[0]
			if (this.selectedFile) {
				this.file.populate({name: row[0].name})
			}
		}
	}
})
</script>