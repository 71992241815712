import session from '@/shared/session'

// initial state
const state = {
	catalogue_defaults: {},

// Patient Registration
	titles: [],
	marital_statuses: [],
	employers: [],
	addresses: [],
	villages: [],
	cities: [],
	districts: [],
	resorts: [],
	biological_genders: [],
	identified_genders: [],
	ethnicities: [],
	nationalities: [],
	twins: [],
	disabilities: [],

// Insurance Data
	insurance_companies: [],
	insurance_types: [],

// Episode Details
	icpc_codes: [],
	episode_statuses: [],

// Consult / De-Cursus Information
	procedure_types: [],

// Health Items
	allergy_codes: [],
	allergy_types: [],
	allergy_severities: [],
	blood_types: [],
	body_temperature_measurement_methods: [],
	pulse_rate_measurement_methods: [],
	heart_rhythms: [],
	pulse_strengths: [],
	heart_rhythm_measurement_types: [],
	palpation_locations: [],
	respiration_measurement_types: [],
	respiration_rhythms: [],
	systolic_measurement_methods: [],
	diastolic_measurement_methods: [],
	blood_pressure_sides: [],
	body_weight_measurement_methods: [],
	body_height_measurement_methods: [],

// Referral Information
	referral_types: [],
	referral_priorities: [],

// Drug Prescription
	medications: [],
	units: [],
	frequency_units: [],
	delivery_forms: [],
	side_effects_statuses: [],
	medication_repeat_dates: [],

// Vaccination Information
	vaccines: [],
	dosage_forms: [],
	route_identifiers: [],
	route_texts: [],
	administered_sites: [],
	side_effects_types: [],
	side_effects_outcomes: [],

// Other
	severities: [], // also for vaccinations
	identification_types: [],
	contact_relations: [],
	research_types: [],
	prescription_dosage_forms: [],

	// referral_tests: [],
}

// getters
const getters = {}

// actions
const actions = {
	async getCatalogueItem({ commit }, catalogue_item) {
		const catalogue = await session.get(`/api/catalogue/${catalogue_item}/`)
		commit("set_catalogue_item", {key: catalogue_item, value: catalogue.data})
		return catalogue.data
	},

	async getCatalogueDefaults ({ commit }) {
		const defaults = await session.get(`/api/catalogue/defaults/`)
		commit("set_catalogue_defaults", defaults.data)
		return state.catalogue_defaults
	},

	async updateCatalogueDefaults({ commit, dispatch }, data) {
		const defaults  = await session
			.put(`/api/catalogue/defaults/`, data)
		
		await dispatch("getCatalogueDefaults")

		return state.catalogue_defaults
	}
}

// mutations
const mutations = {
	set_catalogue_item(state, array) {
		state[array.key] = array.value
	},
	set_catalogue_defaults(state, defaults) {
		state.catalogue_defaults = defaults
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
