<template>
    <corner-popup>
        <b-card id="chart" class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
            <template v-slot:header>
                <i class="fa-regular fa-chart-line"></i> <strong>Vital Functions Chart</strong>
                <div class="card-header-actions">
                    <b-button size="sm" type="button" variant="primary" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></b-button>
                </div>
            </template>

            <b-overlay :show="!items" rounded="sm">
                <line-chart v-if="items" :chart-options="chartOptions" :chart-data="chartData" />
            </b-overlay>
        </b-card>
    </corner-popup>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapActions } from "vuex"

export default ComponentExtension.extend({
    name: 'measurements-chart',
    props: {
        hidden: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            items: null
        }
    },
    computed: {
        chartOptions() {
            return {
                plugins: {
                    legend: {
                        align: 'start'
                    }
                },
                responsive: true,
                maintainAspectRatio: true
            }
        },
        chartData() {
            const datasets = this.items?.data?.map((item) => ({
                label: item.label, // Assuming item has a label property
                backgroundColor: item.color.backgroundColor,
                borderColor: item.color.borderColor,
                borderWidth: 2,
                data: this.combineData(item),
                cubicInterpolationMode: 'monotone',
                // tension: 0.4,
                // fill: 1,
                // stepped: true,
                // pointStyle: 'circle',
                // pointRadius: 5,
                pointHoverRadius: 10
            }))

            return {
                labels: this.items?.labels?.map(item => this.toLocaleDate(item.date_created)),
                datasets: datasets
            }
        }
    },
    methods: {
        ...mapActions('health', ['getVitalsChartDataByPatientId']),
        combineData(item) {
            let { data, dates } = item

            let mappedData = []

            for (let i = 0; i < data.length; i++) {
                let mappedObject = {
                    x: this.toLocaleDate(dates[i]),
                    y: data[i]
                }
                
                mappedData.push(mappedObject)
            }

            return mappedData
        }
    },
    async mounted() {
        this.items = await this.getVitalsChartDataByPatientId()
    }
})
</script>
<style scoped>
    #chart {
        width: 750px;
        margin-bottom: 0;
    }

</style>