<template>
	<div>
		<validation-observer ref="observer" v-slot="{ passes }">
		<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
		    <validation-provider vid="non_field_errors" v-slot="{ errors }">
		      <b-form-invalid-feedback :state="errors[0] ? false : null">
		        <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
		      </b-form-invalid-feedback>
		    </validation-provider>
					
            <validation-provider vid="reason_type" v-slot="{ errors }">
            <b-form-group
	            :invalid-feedback="errors[0]"
	            :state="errors[0] ? false : null">
				<b-input-group prepend="* Reason for appointment">
				    <b-form-select id="reason_type" plain v-model="form.reason_type">
				      <b-form-select-option :value="null">* Select Reason</b-form-select-option>
				      <b-form-select-option v-for="reason in reasonTypes" :key="reason.name" :value="reason.id">{{ reason.name }}</b-form-select-option>
				    </b-form-select>
				</b-input-group>
			</b-form-group>
			</validation-provider>
			<validation-provider vid="reason" v-slot="{ errors }">
			<b-form-group
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
				<b-input-group prepend="* Reason for encounter">	
					<b-form-input ref="rfe" id="reason" placeholder="* (Required)" name="reason" v-model="form.reason" />
        		</b-input-group>
        	</b-form-group>
			</validation-provider>
			<b-form-group>
				<b-form-checkbox name="is_walkin" v-model="form.is_walkin">Is walkin</b-form-checkbox>
			</b-form-group>
			<b-form-group>	
				<b-input-group prepend="Insurance">
				    <b-form-select id="insurance" plain v-model="form.insurance">
				      <b-form-select-option :value="null">Select Insurance</b-form-select-option>
				      <b-form-select-option v-for="insurance in insurances" :disabled="!insurance.is_active" :key="insurance.id" :value="insurance.id">{{ insurance.name }} {{ !insurance.is_active ? '(Inactive)' : '(Active)' }}</b-form-select-option>
				    </b-form-select>
				</b-input-group>
			</b-form-group>
			<b-form-group>
				<b-button type="submit" variant="primary">Create <i class="fa-solid fa-plus"></i></b-button>
			</b-form-group>
		</b-form>
		</validation-observer>
	</div>
</template>
<script>
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default BaseExtension.extend({
	name: 'patient-consultation',
	props: ['patient'],
	data () {
		return {
			form: new Form({
				episode: {
					patient: null,
					consistency_token: null,
				},
				reason_type: null,
				reason: null,
				is_walkin: false,
				insurance: null,
				consistency_token: null,
			}),
			insurances: [],
		}
	},
	computed: {
		...mapGetters('planning', ['getReasonTypes']),
		reasonTypes() {
			return this.getReasonTypes()
		}
	},
	watch: {
		patient: {
            immediate: true,
            handler(val) {
            	this.form.episode.patient = val ?  val.id : null
            	if (val) {
            		this.getInsurances()
            	}
            }
		}
	},
	methods: {
		...mapActions("planning", ["getConsultReasonTypes"]),
		...mapActions('patients', ['createPatientConsultation']),
		...mapActions('insurances', ['getInsurancesByPatientId']),
		onFormSubmit() {
			this.createPatientConsultation({ id: this.patient.id, data: this.form.data() }).then(consultation => {
				if (consultation) this.$router.push({ path: `/schedule/${consultation.id}/` })
			})
            .catch(errors => {
                this.$refs.observer?.setErrors(errors?.response?.data)
            })
		},
		async getInsurances() {
			this.insurances = await this.getInsurancesByPatientId(this.patient?.id)
		}
 	},
	async mounted() {
		await this.getConsultReasonTypes()
	}
})

</script>