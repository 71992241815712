<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ invalid }">
    <b-form @submit.prevent="onFormSubmit" novalidate>
    <validation-provider vid="vaccine_id" v-slot="{ errors }">
        <b-form-group
            :label-size="small ? 'sm' : 'md'"
            label-cols-sm="2"
            label-cols-lg="3"
            label="Vaccine"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <vue-bootstrap-autocomplete name="vaccine_id" v-model="query" :data="vaccines" placeholder="* Search Vaccine..." :minMatchingChars="1" :serializer="vaccineSerializer" @hit="onHitVaccine">
                <template slot="suggestion" slot-scope="{ data, htmlText }">
                    <div class="d-flex align-items-center">
                        <!-- Note: the v-html binding is used, as htmlText contains
                        the suggestion text highlighted with <strong> tags -->
                        <span class="ml-1" v-html="htmlText"></span>
                    </div>
                </template>
            </vue-bootstrap-autocomplete>
        </b-form-group>
    </validation-provider>

    <validation-provider vid="dosage_form_id" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Dosage form"
        label-for="dosage_form_id"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-select id="dosage_form_id" name="dosage_form_id" plain v-model="form.dosage_form_id">
            <b-form-select-option disabled :value="null">* Select Dosage form</b-form-select-option>
            <b-form-select-option v-for="dosage_form in dosage_forms" :key="dosage_form.id" :value="dosage_form.id">{{ dosage_form.name }}
            </b-form-select-option>
        </b-form-select>
    </b-form-group>
    </validation-provider>

    <validation-provider vid="route_text_id" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Route"
        label-for="route_text_id"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <!-- <b-input-group :append="routeID"> -->
        <b-input-group>

            <b-form-select id="route_text_id" name="route_text_id" plain v-model="form.route_text_id">
            <b-form-select-option disabled :value="null">* Select Route Text</b-form-select-option>
            <b-form-select-option v-for="route_text in route_texts" :key="route_text.id" :value="route_text.id">{{ route_text.name }}
            </b-form-select-option>
            </b-form-select>
            <b-form-select disabled id="route_identifyer_id" name="route_identifyer_id" plain v-model="form.route_identifyer_id">
            <b-form-select-option disabled :value="null"></b-form-select-option>
            <b-form-select-option v-for="route_id in route_identifiers" :key="route_id.id" :value="route_id.id">{{ route_id.name }}
            </b-form-select-option>
            </b-form-select>
        </b-input-group>
    </b-form-group>
    </validation-provider>
    <validation-provider vid="administered_site_id" v-slot="{ errors }">
    <b-form-group
        :label-size="small ? 'sm' : 'md'"
        label-cols-sm="2"
        label-cols-lg="3"
        label="Administered Site"
        label-for="administered_site_id"
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : null">
        <b-form-select id="administered_site_id" name="administered_site_id" plain v-model="form.administered_site_id">
            <b-form-select-option disabled :value="null">* Select Administered Site</b-form-select-option>
            <b-form-select-option v-for="administered_site in administered_sites" :key="administered_site.id" :value="administered_site.id">{{ administered_site.name }}
            </b-form-select-option>
        </b-form-select>
    </b-form-group>
    </validation-provider>

    <validation-provider name="Amount" vid="amount" rules="measurement" v-slot="{ errors }">
        <b-form-group
            :label-size="small ? 'sm' : 'md'"
            label-cols-sm="2"
            label-cols-lg="3"
            label="Administered Amount"
            label-for="adm-amount"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <b-input-group>
                <b-form-input type="number" id="adm-amount" v-comma2dot name="amount" placeholder=".00" v-model="form.amount" />
                <template #append>
                    <b-form-select id="unit" name="unit" plain v-model="form.unit">
                        <b-form-select-option disabled :value="null">Unit Amount</b-form-select-option>
                        <b-form-select-option value="ml">ml</b-form-select-option>
                        <b-form-select-option value="mg">mg</b-form-select-option>
                        <b-form-select-option value="IU">IU/IE</b-form-select-option>
                    </b-form-select>
                </template>    
            </b-input-group>  
             <validation-provider vid="unit" v-slot="{ errors }">
                <b-form-invalid-feedback :state="errors[0] ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
            </validation-provider>                                  
        </b-form-group>
    </validation-provider>

    <validation-provider vid="start" v-slot="{ errors }">
        <b-form-group
            :label-size="small ? 'sm' : 'md'"
            label-cols-sm="2"
            label-cols-lg="3"
            label="Vaccination Date"
            label-for="start"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : null">
            <b-form-row>
                <b-col>
                    <b-form-datepicker id="start" placeholder="Set start date..." v-model="form.start" :locale="currentLang" :max="maxDate" @input="onStartSelected"></b-form-datepicker>
                </b-col>
                <b-col>
                    <b-form-datepicker id="end" placeholder="Set end date..." v-model="form.end" :locale="currentLang" :min="form.start"></b-form-datepicker>
                    
                </b-col>
            </b-form-row>
        </b-form-group>
    </validation-provider>

    <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

    </b-form>
    </validation-observer>
    </b-card>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
    name: "vaccinations-form",
    data() {
        return {
            form: null,
            query: "",

            maxDate: new Date(),

            routeID: ""
        }
    },
    computed: {
        ...mapState('catalogue', {
            vaccines: state => state.vaccines,
            dosage_forms: state => state.dosage_forms,
            route_identifiers: state => state.route_identifiers,
            route_texts: state => state.route_texts,
            administered_sites: state => state.administered_sites,
            side_effects_types: state => state.side_effects_types,
            side_effects_outcomes: state => state.side_effects_outcomes
        })

    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        'form.route_text_id': {
            handler(id) {
                if(id) {
                    const rt = this.route_texts.find(item => item.id === id)
                    const ri = this.route_identifiers.find(item => item.code == rt.code)
                }else{
                    this.form.populate({route_identifyer_id: null})
                }
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),        
        vaccineSerializer(item) {
            return item.name
        },
        onHitVaccine(item) {
            this.form.populate({vaccine_id: item.id})
        },
        onStartSelected(date) {
            this.form.populate({end: date})
        },
        onFormSubmit() {
            this.callback(this.form.data()).then(() => {
                this.reset()
                this.notify("Vaccination created", "Vaccination was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                vaccine_id: null,
                dosage_form_id: defaults?.vaccine_dosage_form,
                route_identifyer_id: defaults?.vaccine_route_identifyer,
                route_text_id: defaults?.vaccine_route_text,
                administered_site_id: null,
                side_effects_type_id: null,
                side_effects_outcome_id: null,
                amount: null,
                unit: defaults?.vaccine_administered_unit,
                start: null,
                end: null,
                organization_id: null,
                consistency_token: null,
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            
            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.query = ""
                this.hasErrors = false,
                this.validated()
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('vaccines')
        await this.getCatalogueItem('dosage_forms')
        await this.getCatalogueItem('route_identifiers')
        await this.getCatalogueItem('route_texts')
        await this.getCatalogueItem('administered_sites')
        await this.getCatalogueItem('side_effects_types')
        await this.getCatalogueItem('side_effects_outcomes')
    }
})
</script>