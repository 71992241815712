<template>
<b-tab lazy title-item-class="mb-1">
    <template slot="title">Patient Referrals</template>
    <patient-referrals-base button-top small :callback="createPatientAdminReferral" />
</b-tab>
</template>
<script>
import TabExtension from "../TabExtension"

import ReferralsBase from "@/core/components/referrals/ReferralsBase"
import { mapActions } from "vuex"
export default TabExtension.extend({
    name: "patient-referrals",
    components: {
        'patient-referrals-base': ReferralsBase
    },
    methods: {
        ...mapActions('referrals', ['createPatientAdminReferral']),
    }
})
</script>