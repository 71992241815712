<template>
    <b-card class="card-accent-dark" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-envelope"></i> <strong>Letters</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
            <letters-base borderless has-padding />
        </b-collapse>
    </b-card>
</template>
<script>
import LetterExtension from "./LetterExtension"

import LettersBase from "./LettersBase"
import TemplateBuilder from "./builder/TemplateBuilder"

export default LetterExtension.extend({
    name: 'letters',
    components: {
        'letters-base': LettersBase
    }
})
</script>