// Messaging
const ReportsContainer = () => import("@/views/reports/containers/DefaultContainer")
const ReportsIndex = () => import("@/views/reports/views/StartingPage")
const Procedures = () => import("@/views/reports/views/Procedures")
const Attests = () => import("@/views/reports/views/Attests")

const routes = [
    {
        path: "/reports",
        component: ReportsContainer,
        meta: {
            label: "Reports"
        },
        children: [
            {
                path: "",
                component: ReportsIndex,
                meta: {
                    roleRequired: ["physician"],
                    label: "Dashboard"
                }
            },
            {
                path: "diabetes",
                component: ReportsIndex,
                meta: {
                    roleRequired: ["physician"],
                    label: "Diabetes"
                }
            },
            {
                path: "procedures",
                component: Procedures,
                meta: {
                    roleRequired: ["physician"],
                    label: "Procedures"
                }
            },
            {
                path: "attests",
                component: Attests,
                meta: {
                    roleRequired: ["physician"],
                    label: "Attests"
                }
            }
        ]
    }
]

export default routes
