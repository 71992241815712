<template>
<b-tab no-body>
    <template slot="title">
        Connect to Patient Portal
    </template>
    <div class="d-flex justify-content-center align-items-center p-3">
        <b-overlay
            :show="$store.getters['loading/isLoading']('connect')"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary">
            
                <qrcode :value="qrcode"></qrcode>
                <div class="text-center font-weight-bold"><i class="fa-solid fa-traffic-cone"></i> Under Construction <i class="fa-solid fa-traffic-cone"></i></div>
        </b-overlay>
    </div>
    </b-tab>
</template>
<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import TabExtension from "../TabExtension"
import _ from 'lodash'

export default TabExtension.extend({
    name: 'connect-patient',
    data() {
        return {
            qrcode: null
        }
    },
    methods: {
        ...mapActions('patients', ['getPatientQRCode']),
        async handleMessageReceived(message) {
            if (message == `${this.user?.username}_generated`) {

            }
        },
        async generate() {
            this.qrcode = await this.getPatientQRCode()
        }
    },
    mounted() {
        this.generate()
    },
    beforeDestroy() {
        this.qrcode = null
    }
})
</script>