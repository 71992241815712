<template>
    <b-card @click="openCard" class="thumbnail-card mx-1" no-body>
      <b-card-img
      	class="thumbnail-image"
        :src="card.document_extension === '.pdf' ?  placeholder : card.document_url"
        :alt="card.name"
        fluid
        top></b-card-img>

      <b-card-body>
        <b-card-title><h6 class="mb-0">{{ card.name }}</h6></b-card-title>
        <b-card-text>
        	<template v-if="checkUserPermission('medicalcard', 'can_change')">
				<b-form-input size="sm" class="mb-1" @click.stop="titleReadyOnly = false" placeholder="Add title..." v-model="form.title" :readonly="titleReadyOnly" @blur="titleReadyOnly = true" @keyup="debouncedUpdate" @change="debouncedUpdate" />
				<b-form-input size="sm" @click.stop="descriptionReadyOnly = false" placeholder="Add description..." v-model="form.description" :readonly="descriptionReadyOnly" @blur="descriptionReadyOnly = true"  @keyup="debouncedUpdate" @change="debouncedUpdate" />
        	</template>
        	<template v-else>
        		{{ card.title }}
        		{{ card.description }}
        	</template>
    	</b-card-text>
      </b-card-body>
      <template #footer>
        <small class="text-muted">{{ toLocaleDate(card.created) }}</small>
      </template>
      <b-button
      	v-if="checkUserPermission('medicalcard', 'can_delete')"
        variant="danger"
        size="sm"
        class="remove-btn"
        @click.stop="deleteCard"><i class="fa-solid fa-xmark"></i>
      </b-button>
    </b-card>
</div>
</template>
<script>
import ComponentBaseExtension from '@/core/components/ComponentBaseExtension'
import { mapState, mapGetters, mapActions } from "vuex"
import { Columns } from 'vue-columns'
import { debounce } from 'lodash'

export default ComponentBaseExtension.extend({
	name: "medical-card",
	props: ['card'],
	data() {
		return {
			form: new Form({
				title: null,
				description: null,
				consistency_token: null
			}),

			titleReadyOnly: true,
			descriptionReadyOnly: true,
			debouncedUpdate: null
		}
	},
	computed: {
		placeholder() {
			return '/img/placeholder.png'
		}
	},
    watch: {
        card: {
            immediate: true,
            handler(val) {
                this.form.populate(val)
            }
        }
    },
	methods: {
		...mapActions("patients", ["updateMedicalCard", "deleteMedicalCard"]),
		onChange() {
	    	this.updateMedicalCard({id: this.card.id, data: this.form.data()}).then(medicalcard => {
	    		this.form.populate(medicalcard)
	    	})
		},
		deleteCard() {
			this.dialog("Delete Medical Card Scan", "Are you sure you want to permanently delete this scanned card?").then((result) => {
				if (result) {
					this.deleteMedicalCard(this.card?.id).then(() => {
						this.notify('Card Deleted', `Medical Card deleted`)
					})
		            .catch(errors => {
		                this.notify('Card Error', 'Error deleting medical card', 'danger')
		            })
				}
			})
		},
        openCard() {
            if(this.card?.document_extension === ".pdf") {
                window.open(this.card?.document_url, '_blank')
            }else{
                this.$viewerApi({
                  images: [this.card?.document_url],
                })
            }
        }
	},
    created() {
        // Initialize the debounced function in the correct context
        this.debouncedUpdate = debounce(this.onChange, 1000)
    },
    beforeDestroy() {
        this.debouncedUpdate.cancel()
    }
})
</script>
<style scoped>
.thumbnail-image {
  height: 80px;
  object-fit: contain;
}

.remove-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.thumbnail-card:hover .remove-btn {
  opacity: 1;
}
</style>
