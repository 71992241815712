import session from '@/shared/session'

const state = {
	schedule_date: localStorage.getItem("schedule_date") ? new Date(localStorage.getItem("schedule_date")) : new Date(),
	sidebar: [],
	current: {
		consultation: null,
		patient_arrived: false
	}
}

// getters
const getters = {
	getPatientArrived: (state) => () => {
		return state.current.patient_arrived
	},
	getConsultHasPassed: (state) => () => {
		return state.current.consultation?.is_past
	},
	getPatientProcessed: (state) => () => {
		return state.current.consultation?.processed
	},
	getCanProcessPatient: (state) => () => {
		return state.current.consultation?.can_change_processed
	},
	getCurrentConsultation: (state) => () => {
		return state.current.consultation ||  null
	},
}

// actions
const actions = {
	resetDate({ commit }, episode) {
		commit("set_schedule_date", new Date())
	},

	async updateScheduleDate({ dispatch, commit }, date) {
		commit('set_schedule_date', date)
	},

	async getSideBar ({ state, commit }) {
		const sidebar = await session.get('/api/schedule/sidebar/', {
			params: { date: state.schedule_date.toJSON().slice(0, 10) }
		})

		commit('set_sidebar', sidebar.data)
		return sidebar.data
	},

	async getPatientHasArrived ({ state, commit }) {
		const patient_arrived = await session.get(`/api/planning/consultations/${state.current.consultation?.id}/patient_arrived/`)
		commit("set_patient_arrived", patient_arrived.data)
		return patient_arrived.data
	},

	setPatientHasArrived({ commit, dispatch, state }, has_arrived) {
		dispatch("loading/load", "schedule_arrived", { root: true })
		return session
			.patch(`/api/planning/consultations/${state.current.consultation?.id}/arrived/`, {arrived: has_arrived})
			.then((consultation) => {
				commit("set_consultation", consultation.data)
				return dispatch("getPatientHasArrived")
			})
		.finally(() => {
			dispatch("loading/end", "schedule_arrived", { root: true })
		})
	},

	processPatient({ commit, dispatch, state }, is_processed) {
		dispatch("loading/load", "schedule", { root: true })
		return session
			.patch(`/api/planning/consultations/${state.current.consultation?.id}/change/`, {processed: is_processed})
			.then((consultation) => {
				commit("set_consultation", consultation.data)
				return dispatch("getPatientHasArrived")
			})
		.finally(() => {
			dispatch("loading/end", "schedule", { root: true })
		})
	},

	updateConsultationEpisode({ commit, state }, data) {
		return session
		.patch(`/api/planning/consultations/${state.current.consultation?.id}/change/`, {episode: data})
		.then((consultation) => {
			commit("set_consultation", consultation.data)
			return consultation.data
		})
	},

	async getConsultationById({ dispatch, commit, state }, id) {
		dispatch("loading/load", "schedule_consultation", { root: true })
		const consultation  = await session
			.get(`/api/planning/consultations/${id}/`)

	 	await dispatch("patients/getPatientById", consultation.data?.episode?.patient?.id, { root: true })
	 	await dispatch("episodes/getEpisodeById", consultation.data?.episode?.id, { root: true })
	 	await dispatch("decursus/getDecursusByConsultationId", id, { root: true })

		commit("set_consultation", consultation.data)

		await dispatch("getPatientHasArrived")

		dispatch("loading/end", "schedule_consultation", { root: true })

		return state.consultation
	},

	resetState({ commit }) {
		commit("set_consultation", null)
	},
}

// mutations
const mutations = {
	set_sidebar (state, sidebar) {
		state.sidebar = sidebar
	},
	set_schedule_date(state, schedule_date) {
		localStorage.setItem("schedule_date", schedule_date)
		state.schedule_date = schedule_date
	},
	set_consultation(state, consultation) {
		state.current.consultation = consultation
	},
	set_patient_arrived(state, arrived) {
		state.current.patient_arrived = arrived
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}