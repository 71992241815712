import { Plugin, Command } from '@ckeditor/ckeditor5-core'
import { Typing } from '@ckeditor/ckeditor5-typing'
import { Widget, toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget'
import { createDropdown } from '@ckeditor/ckeditor5-ui'
import { CKEditorError } from '@ckeditor/ckeditor5-utils'

import VariablesNavigationView from './ui/variablesnavigationview.js';
import VariableGridView from './ui/variablegridview.js';
import VariableInfoView from './ui/variableinfoview.js';
import VariablesView from './ui/variablesview.js';
import variablesIcon from '!!raw-loader!./theme/icons/variables.svg'

import './theme/variables.css';
const ALL_SPECIAL_CHARACTERS_GROUP = 'All';


class PlaceholderCommand extends Command {
    execute( { value } ) {
        const editor = this.editor
        const selection = editor.model.document.selection

        editor.model.change( writer => {
            // Create a <placeholder> element with the "name" attribute (and all the selection attributes)...
            const placeholder = writer.createElement( 'placeholder', {
                ...Object.fromEntries( selection.getAttributes() ),
                name: value
            } )

            // ... and insert it into the document. Put the selection on the inserted element.
            editor.model.insertObject( placeholder, null, null, { setSelection: 'on' } )
        } )
    }

    refresh() {
        const model = this.editor.model
        const selection = model.document.selection

        const isAllowed = model.schema.checkChild( selection.focus.parent, 'placeholder' )

        this.isEnabled = isAllowed
    }
}

export default class Variables extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [Typing];
    }
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'Variables';
    }
    /**
     * @inheritDoc
     */
    constructor(editor) {
        super(editor);
        const t = editor.t;
        this._characters = new Map();
        this._groups = new Map();
        this._allSpecialCharactersGroupLabel = t('All');
        editor.commands.add( 'placeholder', new PlaceholderCommand( editor ) )
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;
        // const inputCommand = editor.commands.get('insertText');

        this._defineSchema()
        this._defineConverters()

        const inputCommand = editor.commands.get( 'placeholder' )

        // Add the `variables` dropdown button to feature components.
        editor.ui.componentFactory.add('variables', locale => {
            const dropdownView = createDropdown(locale);
            let dropdownPanelContent;
            dropdownView.buttonView.set({
                label: t('Variables'),
                icon: variablesIcon,
                tooltip: true
            });
            dropdownView.bind('isEnabled').to(inputCommand);
            
            // Insert a special character when a tile was clicked.
            // dropdownView.on('execute', (evt, data) => {
            //     editor.execute('insertText', { text: data.character });
            //     editor.editing.view.focus();
            // });

            // Execute the command when the dropdown item is clicked (executed).
            dropdownView.on('execute', (evt, data) => {
                editor.execute( 'placeholder', { value: data.character } )
                editor.editing.view.focus()
            } )

            dropdownView.on('change:isOpen', () => {
                if (!dropdownPanelContent) {
                    dropdownPanelContent = this._createDropdownPanelContent(locale, dropdownView);
                    const variablesView = new VariablesView(locale, dropdownPanelContent.navigationView, dropdownPanelContent.gridView, dropdownPanelContent.infoView);
                    
                    dropdownView.set({
                        class: 'ck-variables-panel'
                    });

                    dropdownView.panelView.children.add(variablesView);
                }
                dropdownPanelContent.infoView.set({
                    character: null,
                    name: null
                });
            });
            return dropdownView;
        });
    }

    _defineSchema() {
        const schema = this.editor.model.schema

        schema.register( 'placeholder', {
            // Behaves like a self-contained inline object (e.g. an inline image)
            // allowed in places where $text is allowed (e.g. in paragraphs).
            // The inline widget can have the same attributes as text (for example linkHref, bold).
            inheritAllFrom: '$inlineObject',

            // The placeholder can have many types, like date, name, surname, etc:
            allowAttributes: [ 'name' ]
        } )
    }

    _defineConverters() {
        const conversion = this.editor.conversion

        conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'span',
                classes: [ 'placeholder' ]
            },
            model: ( viewElement, { writer: modelWriter } ) => {
                // Extract the "name" from "{name}".
                const name = viewElement.getChild( 0 ).data.slice( 1, -1 )

                return modelWriter.createElement( 'placeholder', { name } )
            }
        } )

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'placeholder',
            view: ( modelItem, { writer: viewWriter } ) => {
                const widgetElement = createPlaceholderView( modelItem, viewWriter )

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget( widgetElement, viewWriter )
            }
        } )

        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'placeholder',
            view: ( modelItem, { writer: viewWriter } ) => createPlaceholderView( modelItem, viewWriter )
        } )

        // Helper method for both downcast converters.
        function createPlaceholderView( modelItem, viewWriter ) {
            const name = modelItem.getAttribute( 'name' )

            const placeholderView = viewWriter.createContainerElement( 'span', {
                class: 'placeholder'
            } )

            // Insert the placeholder name (as a text).
            const innerText = viewWriter.createText( '{' + name + '}' )
            viewWriter.insert( viewWriter.createPositionAt( placeholderView, 0 ), innerText )

            return placeholderView
        }
    }

    /**
     * Adds a collection of special characters to the specified group. The title of a special character must be unique.
     *
     * **Note:** The "All" category name is reserved by the plugin and cannot be used as a new name for a special
     * characters category.
     */
    addItems(groupName, items, options = { label: groupName }) {
        if (groupName === ALL_SPECIAL_CHARACTERS_GROUP) {
            /**
             * The name "All" for a special category group cannot be used because it is a special category that displays all
             * available special characters.
             *
             * @error special-character-invalid-group-name
             */
            throw new CKEditorError('special-character-invalid-group-name', null);
        }
        const group = this._getGroup(groupName, options.label);
        for (const item of items) {
            group.items.add(item.title);
            this._characters.set(item.title, item.character);
        }
    }
    /**
     * Returns special character groups in an order determined based on configuration and registration sequence.
     */
    getGroups() {
        const groups = Array.from(this._groups.keys());
        const order = this.editor.config.get('variables.order') || [];
        const invalidGroup = order.find(item => !groups.includes(item));
        if (invalidGroup) {
            /**
             * One of the special character groups in the "variables.order" configuration doesn't exist.
             *
             * @error special-character-invalid-order-group-name
             */
            throw new CKEditorError('special-character-invalid-order-group-name', null, { invalidGroup });
        }
        return new Set([
            ...order,
            ...groups
        ]);
    }
    /**
     * Returns a collection of special characters symbol names (titles).
     */
    getCharactersForGroup(groupName) {
        if (groupName === ALL_SPECIAL_CHARACTERS_GROUP) {
            return new Set(this._characters.keys());
        }
        const group = this._groups.get(groupName);
        if (group) {
            return group.items;
        }
    }
    /**
     * Returns the symbol of a special character for the specified name. If the special character could not be found, `undefined`
     * is returned.
     *
     * @param title The title of a special character.
     */
    getCharacter(title) {
        return this._characters.get(title);
    }
    /**
     * Returns a group of special characters. If the group with the specified name does not exist, it will be created.
     *
     * @param groupName The name of the group to create.
     * @param label The label describing the new group.
     */
    _getGroup(groupName, label) {
        if (!this._groups.has(groupName)) {
            this._groups.set(groupName, {
                items: new Set(),
                label
            });
        }
        return this._groups.get(groupName);
    }
    /**
     * Updates the symbol grid depending on the currently selected character group.
     */
    _updateGrid(currentGroupName, gridView) {
        // Updating the grid starts with removing all tiles belonging to the old group.
        gridView.tiles.clear();
        const characterTitles = this.getCharactersForGroup(currentGroupName);
        for (const title of characterTitles) {
            const character = this.getCharacter(title);
            gridView.tiles.add(gridView.createTile(character, title));
        }
    }
    /**
     * Initializes the dropdown, used for lazy loading.
     *
     * @returns An object with `navigationView`, `gridView` and `infoView` properties, containing UI parts.
     */
    _createDropdownPanelContent(locale, dropdownView) {
        const groupEntries = Array
            .from(this.getGroups())
            .map(name => ([name, this._groups.get(name).label]));
        // The map contains a name of category (an identifier) and its label (a translational string).
        const specialCharsGroups = new Map([
            // Add a special group that shows all available special characters.
            [ALL_SPECIAL_CHARACTERS_GROUP, this._allSpecialCharactersGroupLabel],
            ...groupEntries
        ]);
        const navigationView = new VariablesNavigationView(locale, specialCharsGroups);
        const gridView = new VariableGridView(locale);
        const infoView = new VariableInfoView(locale);
        gridView.delegate('execute').to(dropdownView);
        gridView.on('tileHover', (evt, data) => {
            infoView.set(data);
        });
        gridView.on('tileFocus', (evt, data) => {
            infoView.set(data);
        });
        // Update the grid of special characters when a user changed the character group.
        navigationView.on('execute', () => {
            this._updateGrid(navigationView.currentGroupName, gridView);
        });
        // Set the initial content of the special characters grid.
        this._updateGrid(navigationView.currentGroupName, gridView);
        return { navigationView, gridView, infoView };
    }
}
