// baseeditor.js
import { ClassicEditor as ClassicEditorBase } from '@ckeditor/ckeditor5-editor-classic'

import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Bold, Italic, Strikethrough, Underline } from '@ckeditor/ckeditor5-basic-styles'
import { Clipboard } from '@ckeditor/ckeditor5-clipboard'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace'
import { Font } from '@ckeditor/ckeditor5-font'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Link } from '@ckeditor/ckeditor5-link'
import { List } from '@ckeditor/ckeditor5-list'
import { Mention } from '@ckeditor/ckeditor5-mention'
import { PageBreak } from '@ckeditor/ckeditor5-page-break'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { PendingActions } from '@ckeditor/ckeditor5-core'
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format'
import { SelectAll } from '@ckeditor/ckeditor5-select-all'
import { ShowBlocks } from '@ckeditor/ckeditor5-show-blocks'
import { SpecialCharacters, SpecialCharactersEssentials } from '@ckeditor/ckeditor5-special-characters'
import { Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar } from '@ckeditor/ckeditor5-table'
import { TextTransformation } from '@ckeditor/ckeditor5-typing'
import { Undo } from '@ckeditor/ckeditor5-undo'
import { WordCount } from '@ckeditor/ckeditor5-word-count'

import FileMenu from './plugins/filemenu/filemenu'
// import Placeholder from './plugins/placeholders/placeholder'
// import { Variables, VariablesPatient, VariablesDecursus } from './plugins/variables'

import LineHeight from './plugins/lineheight/lineheight'

import { Autosave } from '@ckeditor/ckeditor5-autosave'
// import { Image, AutoImage ImageCaption, ImageInsert, ImageResize, ImageStyle, ImageToolbar } from '@ckeditor/ckeditor5-image'
// import { LinkImage } from '@ckeditor/ckeditor5-link'
// import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm'
import { RestrictedEditingMode, StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing'
// import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload'
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing'

export default class ClassicEditor extends ClassicEditorBase {}

ClassicEditor.builtinPlugins = [
    GeneralHtmlSupport,
    Alignment,
    Autoformat,
    BlockQuote,
    Bold,
    Clipboard,
    Essentials,
    FindAndReplace,
    Font,
    Heading,
    Highlight,
    HorizontalLine,
    Indent,
    Italic,
    Link,
    List,
    // Mention,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    PendingActions,
    RemoveFormat,

    SelectAll,
    ShowBlocks,
    SpecialCharacters,
    SpecialCharactersEssentials,
    Strikethrough,

    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,

    TextTransformation,
    Underline,
    Undo,
    WordCount,

    FileMenu,

    // Placeholder,

    // Variables,
    // VariablesPatient,
    // VariablesDecursus,

    Autosave,
    // AutoImage,
    // Image,
    // ImageCaption,
    // ImageInsert,
    // ImageResize,
    // ImageStyle,
    // ImageToolbar,
    // LinkImage,
    // Markdown
    // RestrictedEditingMode,
    StandardEditingMode,
    // SimpleUploadAdapter
    SourceEditing,
    LineHeight
]

ClassicEditor.defaultConfig = {
    htmlSupport: {
        allow: [
            {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true
            }
        ]
    },
    menuBar: {
        isVisible: true,
        items: [
            {
                menuId: 'file',
                label: 'File',
                groups: [
                    {
                        groupId: 'load',
                        items: [
                            'menuBar:new',
                            'menuBar:open',
                        ]
                    },
                    {
                        groupId: 'saving',
                        items: [
                            'menuBar:save',
                            'menuBar:saveAs',
                        ]
                    },
                    {
                        groupId: 'export',
                        items: [
                            'menuBar:pdf',
                            'menuBar:word',
                        ]
                    },
                    {
                        groupId: 'print',
                        items: [
                            'menuBar:print'
                        ]
                    }
                ]
            },
            {
                menuId: 'edit',
                label: 'Edit',
                groups: [
                    {
                        groupId: 'undo',
                        items: [
                            'menuBar:undo',
                            'menuBar:redo',
                        ]
                    },
                    {
                        groupId: 'select',
                        items: [
                            'menuBar:selectAll',
                        ]
                    },
                    {
                        groupId: 'find',
                        items: [
                            'menuBar:findAndReplace',
                        ]
                    }
                ]
            },
            {
                menuId: 'view',
                label: 'View',
                groups: [
                    {
                        groupId: 'show',
                        items: [
                            'menuBar:showBlocks',
                            'menuBar:sourceEditing'
                        ]
                    },
                    {
                        groupId: 'preview',
                        items: [
                            'menuBar:preview',
                        ]
                    }
                ]
            },
            {
                menuId: 'insert',
                label: 'Insert',
                groups: [
                    {
                        groupId: 'line-break',
                        items: [
                            'menuBar:pageBreak',
                            'menuBar:horizontalLine',
                        ]
                    }
                ]
            }
        ]
    },
    toolbar: {
        items: [
            'heading',
            '|', 'bold', 'italic', 'strikethrough', 'underline',
            '|', 'fontfamily', 'fontsize', 'fontcolor', 'fontbackgroundcolor', 'lineHeight', 'removeformat',
            '|', 'highlight',
            '|', 'alignment',
            '|', 'blockquote',
            '|', 'bulletedlist', 'numberedlist', 'outdent', 'indent',
            '|', 'undo', 'redo',
            '|', 'specialcharacters',
            '|', 'insertTable',
            '|', 'placeholder',
            // '|', 'tags',
            '|', 'variables',


            // '|', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells'
            // '|', 'sourceEditing',

            // '-', // break point
            // '|', 'link',
            // '|', 'blockquote', 'selectall',
            // '|', 'highlight', 'horizontalline', 'pagebreak',
            // '|', 'removeformat', 'restrictededitingexception', 'restrictedediting', 'showblocks',
            // '|', 'restrictededitingexception', 'restrictedediting',
            // '|', 'specialcharacters', 'findandreplace'
            // '|', 'insertImage',

        ],
        shouldNotGroupWhenFull: true
    },
    table: {
        contentToolbar: [
            'tableColumn', 'tableRow', 'mergeTableCells',
            'tableProperties', 'tableCellProperties', 'toggleTableCaption'
        ],

        // tableProperties: {
        //     // The configuration of the TableProperties plugin.
        //     // ...
        // },

        // tableCellProperties: {
        //     // The configuration of the TableCellProperties plugin.
        //     // ...
        // }
    },
    lineHeight: {
        options: [
            {
              title: 'Single',
              model: '1.5',
            },
            2, 2.5,
            {
              title: 'Double',
              model: '3',
            },
        ]
    },
    language: 'en'
}
