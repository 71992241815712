<template>
    <div>
        <div v-if="buttonTop" class="mb-3">
            <b-button v-if="checkUserPermission('prescription', 'can_add')" @click="updateButton" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
            <b-collapse id="add" v-model="addNew" variant="primary">
                <b-row class="mt-3">
                    <b-col>
                        <PrescriptionsForm ref="prescriptionsForm" @created="updateButton" :callback="callback" :is-chronic="isChronic" :small="small"/>
                    </b-col>
                    <b-col cols="4"><TemplateSaved ref="prescriptionsTemplatesSaved"/></b-col>
                </b-row>
            </b-collapse>
        </div>

        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE PRESCRIPTION ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('prescriptions')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    striped
                    hover
                    :fixed="fixed"
                    :small="small"
                    :borderless="borderless"
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('prescriptions')"
                    :no-sort-reset="true"
                    :no-border-collapse="false">

                    <template v-slot:cell(full_medication)="data">
                        <span v-if="!data.item.is_repeated">{{ data.value }}</span> <div v-else class="text-center"><i class="fa-regular fa-arrow-turn-down-right"></i> <b-button v-if="data.item.is_repeated" disabled size="sm" variant="secondary" v-b-tooltip.hover title="Repeated Prescription"><i class="fa-solid fa-repeat"></i></b-button> 
                        </div>
                    </template>

                    <template v-slot:cell(directions)="data">
                         <span v-if="!data.item.is_repeated">{{ data.value }}</span>
                    </template>

                    <template v-slot:cell(stoppage_date_time)="data">
                        <template v-if="data.value">
                            <div>{{toLocaleDate(data.value)}}</div>
                            <div class="small">
                                {{data.item.stoppage_reason}}
                            </div>                            
                        </template>
                        <template v-else>
                            <span v-if="!data.item.is_repeated" class="small font-italic text-muted">n/a</span>
                        </template>
                    </template>
                    
                    <template v-slot:cell(total_amount)="data">
                        <span v-if="!data.item.is_repeated">{{ data.value }}</span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button class="mr-2"  size="sm" v-if="checkUserPermission('prescription', 'can_print') && row.item.printable  && !row.item.medication_stopped" variant="secondary" v-b-tooltip.hover title="Print Whole Prescription" @click="printRow(row)"><i class="fa-solid fa-print"></i></b-button>
                        
                        <b-button-group size="sm">
                            <b-button v-if="checkUserPermission('prescription', 'can_print') && row.item.printable && !row.item.medication_stopped" variant="light" v-b-tooltip.hover title="Print Single Prescription" @click="printRow(row, false)"><i class="fa-solid fa-print"></i></b-button>

                            <template v-if="!row.item.is_repeated">
                                <b-button v-if="checkUserPermission('prescription', 'can_change') && !row.item.medication_stopped" variant="warning" @click="toggleDetails(row)" v-b-tooltip.hover title="Stop medication"><i class="fa-solid fa-stop"></i></b-button>
                                <b-button v-if="checkUserPermission('prescription', 'can_change') && row.item.medication_stopped" variant="warning" @click="createPrescriptionCopy(row)" v-b-tooltip.hover title="Copy medication"><i class="fa-solid fa-copy"></i></b-button>
                                <!-- <b-button  v-if="checkUserPermission('prescription', 'can_change') && row.item.editable" variant="primary" v-b-tooltip.hover title="Edit prescription" @click="onEditItem(row)"><i class="fa-solid fa-pen"></i></b-button> -->
                                <b-button  v-if="checkUserPermission('prescription', 'can_delete')" variant="danger" @click="onRemoveItem(row)" v-b-tooltip.hover title="Remove prescription"><i class="fa-light fa-xmark"></i></b-button>                     
                            </template>
                        </b-button-group>
                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                    <template #row-details="row">
                        <StopPrescriptionForm :ref="`stopPrescriptionForm-${row.item.id}`" :item="row.item" :key="row.item.id" />
                    </template>
                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>
                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="4" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer v-if="!buttonTop">
                <b-button v-if="checkUserPermission('prescription', 'can_add')" @click="updateButton" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-row class="mt-3">
                        <b-col>
                            <PrescriptionsForm ref="prescriptionsForm" @created="updateButton" :callback="callback" :is-chronic="isChronic" :small="small"/>
                        </b-col>
                        <b-col cols="4"><TemplateSaved ref="prescriptionsTemplatesSaved"/></b-col>
                    </b-row>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
    </div>
</template>
<script>
import ComponentBaseExtension from "@/core/components/ComponentBaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import PrescriptionsForm from './PrescriptionsForm'
import StopPrescriptionForm from './StopPrescriptionForm'
import TemplateSaved from './templates/TemplateSaved'

import _ from 'lodash'
import printJS from 'print-js'

export default ComponentBaseExtension.extend({
    name: "prescriptions-base",
    props: {
        isChronic: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PrescriptionsForm,
        StopPrescriptionForm,
        TemplateSaved
    },
    data() {
        return {
            addNew: false,
            form: new Form({
                stoppage_reason: "",
                consistency_token: null
            }),

            fields: [
                { key: 'created', label: 'Date/Time', class: {"align-middle" : true, "small": this.small }, formatter: (value) => this.toLocaleDate(value) },
                { key: 'full_medication', label: 'Medication', class: {"align-middle" : true, "small": this.small } },
                { key: 'directions', label: 'Directions', class: {"align-middle" : true, "small": this.small } },
                { key: 'stoppage_date_time', label: 'Stoppage Date/Time', class: {"align-middle" : true, "small": this.small } },
                { key: 'total_amount', label: 'Total', class: {"align-middle" : true, "small": this.small }, formatter: (value) => value ? `${value} units`: 'n/a' },
                // { key: 'id', label: 'ID#', class: {"align-middle" : true, "small": this.small } },
                // { key: 'repeat_original_id', label: 'Parent ID#', class: {"align-middle" : true, "small": this.small }, formatter: (value) => value ? value : 'n/a' },
                // { key: 'repeat', label: 'Repeat', class: 'align-middle' },
                { key: "actions", label: "", class: {"align-middle" : true,  "text-right": true, "small": this.small } },
            ],

            currentPage: 1,
            perPage: 10,

            confirm: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('prescriptions', ['getPatientPrescriptions', 'getPatientChronicPrescriptions']),
        items() {
            return this.isChronic ? _.cloneDeep(this.getPatientChronicPrescriptions()) : _.cloneDeep(this.getPatientPrescriptions())
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        },
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('prescriptions', ['getPrescriptionsByPatientId', 'deletePatientPrescription', 'prescriptionPrintableById']),
        onRemoveItem(row) {
            this.confirm = true
            this.selected = row.item
        },
        onConfirmed() {
            if (this.selected) {
                
                this.deletePatientPrescription(this.selected.id).then(() => {
                    this.notify("Patient prescription removed", "Patient prescription was removed successfully")
                    this.confirm = false
                    this.selected = null

                })
                .catch(errors => {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.selected = null
                })
            }
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog?.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
            // EventBus.$emit('prescription-copied', null)
        },
        async printRow(row, all = true) {
            const data = {id: row.item.id, data: { is_chronic: row.item.is_chronic, print_all: all } }
            const prescription = await this.prescriptionPrintableById(data)
            printJS({
                printable: prescription,
                type: 'pdf',
                base64: true
            })
        },
        createPrescriptionCopy(row){
            EventBus.$emit('prescription-copied', _.cloneDeep(row.item))
            this.addNew = true
        },
        updateButton() {
            this.addNew = !this.addNew
            if(!this.addNew) {
                this.$refs.prescriptionsForm.reset()
                this.$refs.prescriptionsTemplatesSaved.fullReset()
            }
        },
        toggleDetails(row) {
            if (row.detailsShowing) {
                this.$refs[`stopPrescriptionForm-${row.item.id}`].reset()
            }

            row.toggleDetails()
        },
    },
    async mounted() {
        await this.getPrescriptionsByPatientId()
    }
})
</script>
