<template>
<b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Relations

    </template>
    <b-row>            
        <b-col cols="12" md="6">    
            <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
                <b-overlay 
                    :show="$store.getters['loading/isLoading']('update_patient')"
                    rounded="sm"
                    variant="light"
                    :opacity="0.80"
                    blur="2px">

                <validation-provider vid="non_field_errors" v-slot="{ errors }">
                  <b-form-invalid-feedback :state="errors[0] ? false : null">
                    <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
                  </b-form-invalid-feedback>
                </validation-provider>
                <b-form-group :disabled="isLocked" label="Emergency Contact Information">
                    <b-form-group>
                        <b-form-input id="contact_first_name" v-capitalize name="contact_first_name" placeholder="Contact First name" v-model="form.contact_first_name"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-input id="contact_surname" v-capitalize name="contact_surname" placeholder="Contact Surname" v-model="form.contact_surname"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-input type="tel" id="contact_phone_mobile" name="contact_phone_mobile" placeholder="Contact Phone Mobile" v-model="form.contact_phone_mobile"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-select id="contact_relation_id" name="contact_relation_id" plain v-model="form.contact_relation_id">
                            <b-form-select-option :value="null">Select Contact Relation</b-form-select-option>
                            <b-form-select-option v-for="contact_relation in contact_relations" :key="contact_relation.id" :value="contact_relation.id">{{ contact_relation.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-form-group>
                </b-overlay>
                <template v-if="checkUserPermission('patient', 'can_change')">
                    <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                    <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
                    <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
                    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
                </template>
            </b-form>
            </validation-observer>
        </b-col>
       <b-col cols="12" md="6">
            <b-form-group>
                <vue-bootstrap-autocomplete :disabled="isLocked" prepend="Other Patient Relations" v-capitalize v-model="filterQuery" :data="items" placeholder="Search Patient..." :minMatchingChars="1" :serializer="patientSerializer" @hit="onHitRelation" @input="lookupRelation">
                    <template slot="suggestion" slot-scope="{ data, htmlText }">
                       <div class="d-flex w-100 justify-content-between">
                            <small class="ml-1" v-html="htmlText"></small>
                            <small class="ml-auto">{{ toLocaleDate(data.date_of_birth) }}</small>
                        </div>
                    </template>
                    <template slot="noResultsInfo">
                        <span v-if="filterQuery && !isBusy">No patients found</span>
                    </template>
                </vue-bootstrap-autocomplete>
            </b-form-group>
            <b-list-group>
                <template v-if="hasRelations">
                    <b-list-group-item class="py-2 flex-column align-items-start " v-for="(item, index) in relations" :key="index">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <b-link :to="`/patients/${item.id}/edit`">{{ item.name }} - {{ toLocaleDate(item.date_of_birth) }}</b-link>
                        <b-button :disabled="isLocked" size="sm" class="ml-5" variant="danger" @click="removeRelation(item.id)">
                            <i class="fa-solid fa-xmark align-middle"></i>
                        </b-button>
                    </div>
                    <div class="mt-2">
                        <b-form-select size="sm" :disabled="isLocked" plain :value="item.relation_id" @change="updateRelation(item, $event)">
                            <b-form-select-option :value="null">Select Relation</b-form-select-option>
                            <b-form-select-option v-for="contact_relation in contact_relations" :key="contact_relation.id" :value="contact_relation.id">{{ contact_relation.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </div>
                    </b-list-group-item>
                </template>
                <template v-else>
                    <b-list-group-item class="text-center text-muted">No relations</b-list-group-item>
                </template>
            </b-list-group>
        </b-col>
    </b-row>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TabExtension from './TabExtension'
import _ from 'lodash'
export default TabExtension.extend({
    name: 'relations',
    data() {
        return {
            form: new Form({
                contact_surname: "",
                contact_first_name: "",
                contact_relation_id: null,
                contact_phone_mobile: "",
                consistency_token: null,
            }),

            isBusy: false,
            filterQuery: "",
            items: [],
        }
    },
    computed: {
        ...mapGetters('patients', ['getRelations']),
        relations() {
            return this.getRelations()
        },
        ...mapState('catalogue', {
            // Other
            contact_relations: state => state.contact_relations
        }),
        ...mapGetters('patients', ['getPatientDemographics']),
        demographics() {
            return this.getPatientDemographics()
        },
        hasRelations() {
            return this.relations.length > 0
        }
    },
    watch: {
        demographics: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('patients', ['updatePatient', 'createPatientRelation', 'updatePatientRelation', 'deletePatientRelation', 'filterRelatedPatients']),
        patientSerializer(item) {
            return item.name
        },
        async update() {
            await this.updatePatient(this.form.data())
        },
        onFormSubmit() {
            this.updatePatient(this.form.data()).then(() => {
                this.notify('Patient relation updated', "Patient relation was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        onHitRelation(item) {
            const form = Form.create({
                to_patient: item.id,
                relation_id: null,
                consistency_token: null
            })

            this.createPatientRelation(form.data()).then(() => {
                this.filterQuery = ""
                this.notify('Patient relation created', "Patient relation was created successfully")
            })
        },
        lookupRelation: _.debounce(function () {
            this.isBusy = true
            this.filterRelatedPatients({ fullname: this.filterQuery })
                .then(items => {
                    this.items = items
                })
        }, 100),
        updateRelation(item, relation) {
            this.updatePatientRelation({id: item.id, data: {relation_id: relation}}).then(() => {
                this.notify('Patient relation updated', "Patient relation was updated successfully")
            })
        },
        removeRelation(id) {
            this.deletePatientRelation(id).then(() => {
                this.notify('Patient relation removed', "Patient relation was removed successfully")
            })
        },
        reset() {
            this.form.reset()
            
            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.filterQuery = ""
                this.validated()
            })
        }            
    },
    async mounted() {
        // Other
        await this.getCatalogueItem('contact_relations')
    }
})
</script>