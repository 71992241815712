<template>
	<b-button v-if="isDirty" :variant="activeTemplate ? 'warning' : 'success'" class="float-right ml-1" tabindex="3" @click="onSaveTemplate">{{ activeTemplate ? 'Update Template' : 'Create Template'}}</b-button>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import { debounce } from 'lodash'

export default FormExtension.extend({
    name: "prescriptions-template-button",
    data() {
        return {
            form: new Form({
                medication_id: null,
                other: "",
                dosage: {
                    medication_id: null,
                    other: "",
                    is_chronic: false,
                    repeat_prescription: 0,
                    repeat_date_id: null,
                    strength: null,
                    unit_id: null,
                    frequency_usage: null,
                    frequency_unit_id: null,
                    dosage_amount: null,
                    dosage_form_id: null,
                    amount_of_time: null,
                    delivery_form_id: null,
                    instructions: "",
                },
                consistency_token: null
            }),

            activeTemplate: null
        }
    },
    computed: {

    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
            }
        }
    },
    methods: {
        ...mapActions('prescriptions', ['createPrescriptionTemplate', 'updatePrescriptionTemplate']),
        prepareTemplate(data) {
	        this.form.populate({
	            medication_id: data?.medication_id,
	            other: data?.other,
	            dosage:{
	                medication_id: data?.medication_id,
	                other: data?.other,
	                is_chronic: data?.is_chronic || false,
	                repeat_prescription: data?.repeat_prescription || 0,
	                repeat_date_id: data?.repeat_date_id || null,
	                strength: data?.strength,
	                unit_id: data?.unit_id,
	                frequency_usage: data?.frequency_usage,
	                frequency_unit_id: data?.frequency_unit_id,
	                dosage_amount: data?.dosage_amount,
	                dosage_form_id: data?.dosage_form_id,
	                amount_of_time: data?.amount_of_time,
	                delivery_form_id: data?.delivery_form_id,
	                instructions: data?.instructions,
	            }
	        })
        },
        loadFromSavedTemplate(val) {
        	this.activeTemplate = val
            this.prepareTemplate(val)
        },
        reset() {
            this.form.reset()
            this.activeTemplate = null
        },
        onSaveTemplate() {
            if (this.activeTemplate) {
                this.updatePrescriptionTemplate({id: this.activeTemplate?.id, data: this.form.data() }).then((template) => {
                    this.prepareTemplate(template)
                    
                    EventBus.$emit('prescription-get-templates')

                    this.notify("Prescription template updated", "Prescription template was updated successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                        this.hasErrors = true
                    }
                })
            }else{
                this.createPrescriptionTemplate(this.form.data()).then((template) => {
                    this.loadFromSavedTemplate(template)
                    
                    EventBus.$emit('prescription-get-templates')
                    
                    this.notify("Prescription template created", "Prescription template was created successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                        this.hasErrors = true
                    }
                })
            }
        }
    },
    mounted() {
    	EventBus.$on('prescription-template-loaded-from-template', (template) => {
            this.loadFromSavedTemplate(template)
        })

        EventBus.$on('prescription-reset-template', () => {
            this.reset()
        })
    },
    beforeDestroy() {
         EventBus.$off("prescription-template-loaded-from-template")
         EventBus.$off("prescription-reset-template")
    }
})
</script>