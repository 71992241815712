import session from '@/shared/session'
import _ from 'lodash'

// initial state
const initialState = {
	plugins: [],
	current: {
		id: null,
		demographics: {},
		relations: [],
		memo: {},
		medicalcards: [],
		decursus: [],
		episodes:[],
		logs: [],
		exports: [],
		current_consult: null,
	},

	physicians: []
}

const state = _.cloneDeep(initialState)

// getters
const getters = {
	getPatientCurrentConsult: (state) => () => {
		return state.current.current_consult
	},
	getPatientDemographics: (state) => () => {
		return state.current.demographics
	},
	getRelations: (state) => () => {
		return state.current.relations
	},
	getPatientMemo: (state) => () => {
		return state.current.memo
	},
	getPatientMedicalCards: (state) => () => {
		return state.current.medicalcards
	},
	getPatientEpisodes: (state) => () => {
		return state.current.episodes
	},
	getPatientLogs: (state) => () => {
		return state.current.logs
	},
	getPatientExports: (state) => () => {
		return state.current.exports
	},
}

// actions
const actions = {
	async resetState ({ commit, dispatch }) {
			// await dispatch("insurances/resetState", null, { root: true })
			commit('reset_state')
			return
	},

	async getPhysicians({ commit, state }) {
		const physicians = await session.get('/api/patients/physicians/')
		commit("set_physicians", physicians.data)
		return state.physicians
	},

	async filterRelatedPatients({ commit, state }, params) {
		const patients = await session
			.get(`/api/patients/${state.current.id}/related/`, {
				params: params,
			})
		return patients.data
	},

	async filterPatients({ commit }, params) {
		const patients = await session
			.get("/api/patients/", {
				params: params,
			})
		return patients.data
	},

	async filterEmployers({ commit, state }, params) {
		const items = await session
			.get('/api/catalogue/employers/', {
					params: params
			})

		return items.data
	},

	createEmployer({ commit }, data) {
		return session
				.post(`/api/catalogue/employers/`, data)
				.then((employer) => {
					return employer.data
				})
	},

	deleteEmployer({ commit, dispatch }, id) {
		return session
			.delete(`/api/catalogue/employers/${id}/`)
	},

	async getRelationsForCurrentPatient({ commit, state }) {
		const relations = await session
			.get(`/api/patients/${state.current.id}/relations/`)

		commit("set_current", {key: 'relations', value: relations.data})

		return state.current.relations
	},

	async getMedicalCardsForCurrentPatient({ commit, state }) {
		const medicalcards = await session
			.get(`/api/patients/${state.current.id}/medicalcards/`)

		commit("set_current", {key: 'medicalcards', value: medicalcards.data})

		return state.current.medicalcards
	},

	async getMemoForCurrentPatient({ commit, state }) {
		const memo = await session
			.get(`/api/patients/${state.current.id}/memo/`)

		commit("set_current", {key: 'memo', value: memo.data})

		return state.current.memo
	},

	async getDecursusForCurrentPatient({ commit, state }) {
		const decursus = await session
			.get(`/api/patients/${state.current.id}/decursus/`)

		commit("set_current", {key: 'decursus', value: decursus.data})

		return state.current.decursus
	},

	async getEpisodesForCurrentPatient({ commit, state }) {
		const episodes = await session
			.get(`/api/patients/${state.current.id}/episodes/`)

		commit("set_current", {key: 'episodes', value: episodes.data})

		return state.current.episodes
	},

	async getLogTypesForCurrentPatient({ state }) {
		const log_types = await session
			.get(`/api/patients/${state.current.id}/log_types/`)

		return log_types.data
	},

	async getLogsForCurrentPatient({ commit, dispatch, state }, params) {
		dispatch("loading/load", "logs", { root: true })
		const logs = await session
			.get(`/api/patients/${state.current.id}/logs/`, { params: params })

		commit("set_current", {key: 'logs', value: logs.data})
		dispatch("loading/end", "logs", { root: true })
		return state.current.logs
	},

	async getExportsForCurrentPatient({ commit, dispatch, state }) {
		dispatch("loading/load", "exports", { root: true })
		const exports = await session
			.get(`/api/patients/${state.current.id}/exports/`)

		commit("set_current", {key: 'exports', value: exports.data})
		dispatch("loading/end", "exports", { root: true })
		return state.current.exports
	},

	async getRunningExportsForCurrentPatient({ commit, dispatch, state }) {
		const exports_running = await session
			.get(`/api/patients/${state.current.id}/running_exports/`)

		return exports_running.data.running
	},

	async getPatientById({ commit, dispatch, state }, id) {
		const [demographics, relations, memo, medicalcards, logs, current_consult] = await Promise.all([
			session.get(`/api/patients/${id}/`),
			session.get(`/api/patients/${id}/relations/`),
			session.get(`/api/patients/${id}/memo/`),
			session.get(`/api/patients/${id}/medicalcards/`),
			session.get(`/api/patients/${id}/logs/`),
			session.get(`/api/patients/${id}/current_consult/`),
		])

		await dispatch("insurances/getInsurancesByPatientId", id, { root: true })

		commit("set_current", {key: 'id', value: id})
		commit("set_current", {key: 'demographics', value: demographics.data})
		commit("set_current", {key: 'relations', value: relations.data})
		commit("set_current", {key: 'memo', value: memo.data})
		commit("set_current", {key: 'medicalcards', value: medicalcards.data})
		commit("set_current", {key: 'logs', value: logs.data})
		commit("set_current", {key: 'current_consult', value: current_consult.data})

		return state.current
	},

	exportCurrentPatient({ commit, state, dispatch }, { type, episodes }) {
		dispatch("loading/load", "export_patient", { root: true })
		return session
				.post(`/api/patients/${state.current.id}/export/`, {type: type, episodes: episodes})
				.then(() => {
					return dispatch("getExportsForCurrentPatient")
				})
	      .finally(() => {
	        dispatch("loading/end", "export_patient", { root: true })
	      })
	},

	createPatient({ commit, state, dispatch }, data) {
		dispatch("loading/load", "create_patient", { root: true })
		return session
				.post(`/api/patients/`, data)
				.then((demographics) => {
					commit("set_current", {key: 'id', value: demographics.data.id})
					commit("set_current", {key: 'demographics', value: demographics.data})

					return state.current
				})
	      .finally(() => {
	        dispatch("loading/end", "create_patient", { root: true })
	      })
	},

	updatePatient({ commit, state, dispatch }, data) {
		dispatch("loading/load", "update_patient", { root: true })
		return session
			.put(`/api/patients/${state.current.id}/`, data)
			.then((demographics) => {
				commit("set_current", {key: 'demographics', value: demographics.data})

				return demographics.data
			})
      .finally(() => {
        dispatch("loading/end", "update_patient", { root: true })
      })
	},

	createPatientRelation({ commit, state, dispatch }, data) {
		dispatch("loading/load", "patient_relation", { root: true })
		return session
				.post(`/api/patients/relations/`, {...data, patient: state.current.id})
				.then(() => {
					return dispatch("getRelationsForCurrentPatient")
				})
	      .finally(() => {
	        dispatch("loading/end", "patient_relation", { root: true })
	      })
	},

	updatePatientRelation({ commit, state, dispatch }, { id, data }) {
		dispatch("loading/load", "patient_relation", { root: true })
		return session
			.patch(`/api/patients/relations/${id}/`, data)
			.then(() => {
				return dispatch("getRelationsForCurrentPatient")
			})
      .finally(() => {
        dispatch("loading/end", "patient_relation", { root: true })
      })
	},
	
	deletePatientRelation({ commit, dispatch }, id) {
    	dispatch("loading/load", "patient_relation", { root: true })
		return session
			.delete(`/api/patients/relations/${id}/`)
			.then(() => {
				return dispatch("getRelationsForCurrentPatient")
			})
			.finally(() => {
				dispatch("loading/end", "patient_relation", { root: true })
			})
	},

	async getPatientLastConsultation({ commit }, id) {
		const last_consult  = await session
			.get(`/api/patients/${id}/last_consult/`)
		return last_consult.data
	},

	async getPatientCurrentConsultation({ commit }, id) {
		const current_consult  = await session
			.get(`/api/patients/${id}/current_consult/`)
		return current_consult.data
	},

	async getPatientFutureConsultations({ commit }, id) {
		const consultations  = await session
			.get(`/api/patients/${id}/future_consults/`)
		return consultations.data
	},

	async getPatientQRCode({ commit, state, dispatch }) {
		dispatch("loading/load", "connect", { root: true })
		const qrcode  = await session
			.get(`/api/patients/${state.current.id}/qrcode/`)
		dispatch("loading/end", "connect", { root: true })	
		return qrcode.data
	},

	createPatientConsultation({ state, dispatch }, { id, data }) {
		dispatch("loading/load", "consultation", { root: true })
		return session
			.post(`/api/patients/${id}/consult/`, data)
			.then((consultation) => {
				return consultation.data
			})
			.finally(() => {
				dispatch("loading/end", "consultation", { root: true })
			})
	},

	createPatientMemo({ commit, state, dispatch }, data) {
		dispatch("loading/load", "memo", { root: true })
		return session
			.post(`/api/patients/memo/`, {...data, patient: state.current.id})
			.then(memo => {
				commit("set_current", {key: 'memo', value: memo.data})

			})
		.finally(() => {
				dispatch("loading/end", "memo", { root: true })
		})
	},

	updatePatientMemo({ commit, dispatch }, { id , data}) {
		dispatch("loading/load", "memo", { root: true })
		return session
				.put(`/api/patients/memo/${id}/`, data)
				.then(memo => {
					commit("set_current", {key: 'memo', value: memo.data})
				})
		.finally(() => {
				dispatch("loading/end", "memo", { root: true })
		})
	},

	uploadMedicalCard({ commit, dispatch }, data) {
    dispatch("loading/load", "medicalcards", { root: true })
		return session
			.post(`/api/patients/medicalcards/`, data)
			.then(() => {
				return dispatch("getMedicalCardsForCurrentPatient")
			})
			.finally(() => {
				dispatch("loading/end", "medicalcards", { root: true })
			})
	},

	updateMedicalCard({ commit, dispatch }, {id, data}) {
		dispatch("loading/load", "update_medicalcard", { root: true })
		return session
		.patch(`/api/patients/medicalcards/${id}/`, data)
		.then((medicalcard) => {
			return dispatch("getMedicalCardsForCurrentPatient")
		})
    .finally(() => {
      dispatch("loading/end", "update_medicalcard", { root: true })
    })

	},

	deleteMedicalCard({ commit, dispatch }, id) {
    	dispatch("loading/load", "medicalcards", { root: true })
		return session
			.delete(`/api/patients/medicalcards/${id}/`)
			.then(() => {
				return dispatch("getMedicalCardsForCurrentPatient")
			})
			.finally(() => {
				dispatch("loading/end", "medicalcards", { root: true })
			})
	},

	updatePatientStatus({ commit, dispatch }, status) {
		dispatch("loading/load", "update_patient", { root: true })
		return session
		.patch(`/api/patients/${state.current.id}/status/`, {blocked: status})
		.then((demographics) => {
			commit("set_current", {key: 'demographics', value: demographics.data})

			return demographics.data
		})
    .finally(() => {
      dispatch("loading/end", "update_patient", { root: true })
    })
	}
}

// mutations
const mutations = {
	set_plugins(state, plugins) {
		state.plugins = plugins
	},
	set_physicians(state, physicians) {
		state.physicians = physicians
	},
	set_current(state, obj) {
		state.current[obj.key] = obj.value
	},
	reset_state(state) {
		Object.assign(state['current'], initialState['current'])
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
